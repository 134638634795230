import React from 'react';
import { useIntl } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import { getProgressBarProperties } from './ApplicationStatusBannerUtils';

import { APPLICATION_STATUS_TO_STEP_MAP, STEP_TO_ICON_MAP, STEP_TO_TITLE_MAP } from './ApplicationStatusBannerConstants';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import './ApplicationStatusBanner.scss';

const ApplicationStatusBanner = ({
    status,
    isInterviewPending,
    isInterviewConfirmed,
}) => {
    const intl = useIntl();
    const step = APPLICATION_STATUS_TO_STEP_MAP[status](isInterviewPending, isInterviewConfirmed);

    return (
        <div className="application-status-banner-container">
            {STEP_TO_ICON_MAP[step]}
            <p className="status-text">{intl.formatMessage({id: convertStringToLanguageKey(STEP_TO_TITLE_MAP[step])})}</p>
            <div className="steps-list-container">
                {getProgressBarProperties(step).map((properties, idx) => <LinearProgress key={idx} {...properties} />)}
            </div>
        </div>
    )
};

export default ApplicationStatusBanner;
