import { SWITCH_LANGUAGE_SUCCESS } from './actionTypes';

import userService from 'services/userService';
import { authorizedShowNotification } from './actionHelpers';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { LANGUAGE_MESSAGES } from 'constants/messageConstants';

export const switchLanguageSuccess = (payload) => ({
    type: SWITCH_LANGUAGE_SUCCESS,
    payload,
});

export const switchLanguage = (userId, newLanguage) => dispatch => {
    try {
        const query = `?language=${newLanguage}`;

        if (userId) {
            userService.changeUserPreferredLanguage(userId, query);
        }

        dispatch(switchLanguageSuccess(newLanguage));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(LANGUAGE_MESSAGES.SWITCH_LANGUAGE_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};
