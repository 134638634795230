import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { getStateAgencyName } from 'utils/helpers';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { Typography } from '@material-ui/core';

import './ThankYouBanner.scss';

const ThankYouBanner = () => {
    const intl = useIntl();

    return (
        <div className="thank-you-banner-wrapper">
            <div className="thank-you-banner-container">
                <div className="thank-you-header-message-container">
                    <Typography className="thank-you-header-message">
                        {intl.formatMessage({ id: "candidate-complete-profile-title" })}
                    </Typography>
                </div>
                <div className="thank-you-message-container">
                    <Typography className="thank-you-message">
                        {intl.formatMessage({ id: "candidate-complete-profile-content" })}
                    </Typography>
                </div>
                <PrimaryButton
                    component={Link}
                    text={intl.formatMessage({ id: "jobs" })}
                    to={`/${getStateAgencyName()}/job-opportunities`}
                    className="jobs-button"
                />
            </div>
        </div>
    )
};

export default ThankYouBanner;
