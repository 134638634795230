import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { showSkillsPopupInScreen } from 'utils/uiHelpers';
import { isMobile } from 'react-device-detect';

import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';
import DesktopCardHeader from './DesktopCardHeader';
import MobileCardHeader from './MobileCardHeader';

import { ReactComponent as VoteIcon } from 'assets/icon-vote.svg';

import { RECOMMENDED_JOBS, SHOWING_SKILLS_COUNT } from '../JobsConstants';

import './JobCard.scss'

const JobCard = ({
    jobOpportunity,
    jobOpportunityDetailsLink,
    selectedJobsType,
    handleJobRecommendationRatingClick,
}) => {
    const intl = useIntl();

    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({ transform: 'translateX(-50%)' });

    const firstNSkills = jobOpportunity.skills.found?.slice(0, SHOWING_SKILLS_COUNT) || [];
    const restSkills = jobOpportunity.skills.found?.slice(SHOWING_SKILLS_COUNT) || [];

    //Handle click on rest skills
    const handleRestSkillsClick = (e, jobOpportunity) => {
        if (isMobile) {
            e.stopPropagation();
            e.preventDefault();

            if (jobOpportunity && !isShowMorePopupVisible) {
                showSkillsPopupInScreen(`rest-skills-popup-${jobOpportunity._id}`, setPopupTransform);
            }

            setIsShowMorePopupVisible((oldState) => !oldState);
        }
    }

    //Handle mouse over on rest skills
    const handleRestSkillsHover = (visibility, jobOpportunity) => {
        if (!isMobile || !visibility) {
            setIsShowMorePopupVisible(visibility);

            if (jobOpportunity && visibility) {
                showSkillsPopupInScreen(`rest-skills-popup-${jobOpportunity._id}`, setPopupTransform);
            }
        }
    };

    return (
        <Link className="job-card" to={jobOpportunityDetailsLink(jobOpportunity._id)}>
            <JobTypeLabel jobType={jobOpportunity.jobType} />
            <div className="inner-card">
                { isMobile ? <MobileCardHeader jobOpportunity={jobOpportunity} /> : <DesktopCardHeader jobOpportunity={jobOpportunity} /> }
                { !isMobile && <Divider className="card-divider" variant="middle" /> }
                {jobOpportunity.jobSummary !== '' && !isMobile &&
                    <div className="job-description">
                        <h4>{intl.formatMessage({ id: "job-description" })}</h4>
                        <pre>{jobOpportunity.jobSummary}</pre>
                    </div>
                }
                {
                    (jobOpportunity.skills?.found?.length > 0 &&
                        <div className="card-footer">
                            <h4>{intl.formatMessage({ id: "matching-skills" })}</h4>
                            <div className="skills-list">
                                {
                                    jobOpportunity.skills.found?.length > 0 &&
                                    firstNSkills.map((skill, i) => (
                                        <span key={i} className={`matching-skill ${jobOpportunity.isProConsultantOpportunity ? "skill pro-skill" : "skill"}`}>
                                            <p>{skill.Skill}</p>
                                        </span>
                                    ))
                                }
                                {restSkills?.length > 0 && (
                                    <div
                                        className="rest-skills-container"
                                        onMouseOver={() => handleRestSkillsHover(true, jobOpportunity)}
                                        onMouseLeave={() => handleRestSkillsHover(false)}
                                        onClick={(e) => handleRestSkillsClick(e, jobOpportunity)}
                                    >
                                        <span id={`rest-skills-text-${jobOpportunity._id}`} className="rest-skills-count">
                                            {`+ ${restSkills?.length} ${intl.formatMessage({ id: "more" })}`}
                                        </span>
                                        <div id={`rest-skills-popup-${jobOpportunity._id}`} className={`rest-skills-popup ${isShowMorePopupVisible}`} style={popupTransform}>
                                            {restSkills.map((skill) => (
                                                <div key={`${skill.Skill}`} className={`matching-skill ${jobOpportunity.isProConsultantOpportunity ? "skill pro-skill" : "skill"}`}>
                                                    <p>{skill.Skill}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                {(jobOpportunity.isMatch && selectedJobsType === RECOMMENDED_JOBS) &&
                    <div className="rate-buttons-wrapper">
                        <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={intl.formatMessage({ id: "bad-job-recommendation" })} placement='bottom-end'>
                            <div
                                className={`vote-icon-wrapper downvote${jobOpportunity.recommendationRating?.isGoodRecommendation === false ? ' active' : ''}`}
                                onClick={(event) => handleJobRecommendationRatingClick(event, jobOpportunity, false)}
                            >
                                <VoteIcon />
                            </div>
                        </Tooltip>
                        <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={intl.formatMessage({ id: "good-job-recommendation" })} placement='bottom-end'>
                            <div
                                className={`vote-icon-wrapper upvote${jobOpportunity.recommendationRating?.isGoodRecommendation === true ? ' active' : ''}`}
                                onClick={(event) => handleJobRecommendationRatingClick(event, jobOpportunity, true)}
                            >
                                <VoteIcon />
                            </div>
                        </Tooltip>
                    </div>
                }
            </div>
        </Link >
    )
}

export default JobCard;
