import React from 'react';
import { useIntl } from 'react-intl';

import './CvCompletion.scss';

const CvCompletion = ({
    cvCompletionRate
}) => {
    const intl = useIntl();

    return (
        <div className="cv-completion-container">
            <h1 className="title">{intl.formatMessage({ id: "your-cv-is-now" })} {parseInt(cvCompletionRate)}% {intl.formatMessage({ id: "complete" })}!</h1>
            <p className="summary">{intl.formatMessage({ id: "cv-completion-text" })}</p>
        </div>
    )
};

export default CvCompletion;
