import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Paper } from '@material-ui/core';

import VideoPlayer from 'components/Shared/VideoPlayer';
import ApplicationCustomTooltip from 'components/MyApplications/ApplicationDetails/Application/ApplicationCustomTooltip';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import CalendlyDialog from 'components/Shared/CalendlyDialog';
import NothingToShow from 'components/NothingToShow';

import { DATE_SHORT_YEAR, FORMAT_TIMES_MAP } from 'constants/timesFormat';

import { ReactComponent as CalenldyLogo } from 'assets/calendly.svg';

import './InterviewRecordingsSection.scss';
import { convertStringToLanguageKey } from 'utils/languageUtils';

const InterviewRecordingsSection = ({
    title,
    videos,
    hasBookedInterview,
    calendlyInterviewInfo,
    isAdminMode,
    isCandidateMode,
    calendlyInfo,
    handleEventScheduling,
    isDeleted,
}) => {
    const intl = useIntl();

    const hasVideos = videos && videos?.length > 0;
    const [showTooltip, setShowTooltip] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getDate = (date) => moment(date).format(DATE_SHORT_YEAR);

    const getHour = (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format(FORMAT_TIMES_MAP.TIME);
        const endDateFormatted = moment(endDate).format(FORMAT_TIMES_MAP.TIME);

        return `${startDateFormatted} - ${endDateFormatted}`;
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    }

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    }

    return (
        ((hasVideos && isCandidateMode) || isAdminMode) &&
        <div className="video-recordings">
            <div className="recordings-wrapper">
                {(hasBookedInterview && isAdminMode)
                    ?
                    <div className="video-recordings-wrapper calendly-wrapper">
                        <div className="candidate-interview-face-to-face">
                            <CalenldyLogo className="icon" />
                            <p>
                                {intl.formatMessage({ id: "candidate-invited-via-calendly-text" })}
                            </p>
                        </div>
                        <div className="face-to-face-footer">
                            <div className="info-item"><span className="bold">{intl.formatMessage({ id: "date" })}: </span>{getDate(calendlyInterviewInfo.startDateTime)}</div>
                            <div className="info-item"><span className="bold">{intl.formatMessage({ id: "hour" })}: </span>{getHour(calendlyInterviewInfo.startDateTime, calendlyInterviewInfo.endDateTime)}</div>
                            <div className="info-item"><span className="bold">{intl.formatMessage({ id: "recruiter" })}: </span>{calendlyInterviewInfo.recruiter}</div>
                            <div className="info-item"><span className="bold">{intl.formatMessage({ id: "status" })}: </span>{calendlyInterviewInfo.status}</div>
                        </div>
                    </div>
                    : (!hasVideos && isAdminMode)
                        ?
                        <Paper className="no-videos-available-wrapper">
                            <Typography className="title">{intl.formatMessage({ id: "video-introduction" })}</Typography>
                            <div className="no-videos-available-content">
                                <NothingToShow
                                    message={
                                        <div className="no-videos-available-message">
                                            <p className="no-videos-available-text">{intl.formatMessage({ id: "no-video-to-show" })}</p>
                                            <InfoOutlinedIcon
                                                className="global-information-icon"
                                                onMouseEnter={() => setShowTooltip(true)}
                                                onMouseLeave={() => setShowTooltip(false)}
                                            />
                                            {showTooltip &&
                                                <ApplicationCustomTooltip styles={{ width: '285px', top: '30px', left: '0px' }}>
                                                    {intl.formatMessage({ id: "no-video-to-show-text" })}
                                                </ApplicationCustomTooltip>
                                            }
                                        </div>
                                    }
                                />

                                {(calendlyInfo.isUsingCalendly && !isDeleted) &&
                                    <div className="action-buttons-wrapper">
                                        <PrimaryButton
                                            text={intl.formatMessage({ id: "invite-via-calendly" })}
                                            handleClick={handleOpenDialog}
                                        />
                                    </div>
                                }
                            </div>
                        </Paper>
                        : <div className="video-recordings-wrapper">
                            <Typography variant="h5" className="title">{intl.formatMessage({ id: convertStringToLanguageKey(title) })}</Typography>
                            {videos.map(video => (
                                <div key={video.question._id} className="recording-wrapper">
                                    <Typography variant="h6" className="sub-title">{intl.formatMessage({ id: "question" })}</Typography>
                                    {console.log(video.question.transcription)}
                                    <p className="question">{intl.formatMessage({ id: convertStringToLanguageKey(video.question.transcription)})}</p>
                                    <VideoPlayer videoUrl={video.temporarySignedUrl} />
                                </div>
                            ))}
                        </div>
                }
            </div>

            {
                isDialogOpen &&
                <CalendlyDialog
                    handleClose={handleCloseDialog}
                    calendlyInfo={calendlyInfo}
                    handleEventScheduling={handleEventScheduling}
                    isAdminMode={isAdminMode}
                />
            }
        </div>
    )
}

export default InterviewRecordingsSection;
