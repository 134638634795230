import React from 'react';
import { useIntl } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';

import { ReactComponent as InfoIcon } from "assets/info-icon.svg";

import { HINT } from './VideoIntroductionConstants';

import './VideoIntroductionView.scss'

const VideoIntroductionView = ({
    canBookCalndlyCall,
    agencyName,
    permission,
    setShowHintPopup,
    showHintPopup,
    handleAudioChange,
    audioInputSelected,
    audioInputList,
    handleVideoChange,
    videoInputSelected,
    videoInputList,
    videoRef,
}) => {
    const intl = useIntl();

    return (
        <PaperElement classes={["sm"]}>
            <PaperHeader title={intl.formatMessage({ id: "personal-introduction" })} classes={["video-introduction-header-flex"]}>
                <InfoIcon onMouseEnter={() => setShowHintPopup(true)} onMouseLeave={() => setShowHintPopup(false)} />
                {
                    showHintPopup &&
                    <div className="info-text">
                        {HINT(intl, agencyName)}
                    </div>
                }
            </PaperHeader>
            <PaperContent>
                <p className="paper-element-description">{intl.formatMessage({ id: "video-introduction-text" })}{canBookCalndlyCall && ` ${intl.formatMessage({ id: "video-introduction-calendly-text" })}`} </p>

                <div className="input-container">
                    <p className="paper-element-description">{intl.formatMessage({ id: "do-you-allow-access-to-your-microphone" })}</p>

                    <div className="row">
                        <TextField
                            className="base-input"
                            variant="outlined"
                            select
                            disabled={!permission}
                            id="select-audio-device"
                            name="select-audio-device"
                            onChange={(event) => { handleAudioChange(event) }}
                            label={intl.formatMessage({ id: "select-audio-input" })}
                            InputLabelProps={{ shrink: true }}
                            value={audioInputSelected.label || ""}
                            fullWidth
                        >
                            {audioInputList.map((device, i) => (
                                <MenuItem key={device.deviceId} value={device.label}>
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>

                <div className="input-container">
                    <p className="paper-element-description">{intl.formatMessage({ id: "do-you-allow-access-to-your-camera" })}</p>

                    <div className="row">
                        <TextField
                            className="base-input"
                            variant="outlined"
                            select
                            disabled={!permission}
                            id="select-video-device"
                            name="select-video-device"
                            onChange={(event) => { handleVideoChange(event) }}
                            label={intl.formatMessage({ id: "select-video-input" })}
                            InputLabelProps={{ shrink: true }}
                            value={videoInputSelected.label || ""}
                            fullWidth
                        >
                            {videoInputList.map((device, i) => (
                                <MenuItem key={device.deviceId} value={device.label}>
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className="camera-container">
                    <video ref={videoRef} muted controls={false}>
                        {intl.formatMessage({ id: "video-stream-not-available" })}
                    </video>
                </div>
            </PaperContent>
        </PaperElement>
    )
};

export default VideoIntroductionView;
