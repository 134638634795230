import React from 'react'
import moment from 'moment';
import { useIntl } from 'react-intl';

import Avatar from '@material-ui/core/Avatar';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
import PlaceholderImage from 'assets/placeholder-image.jpg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const DesktopCardHeader = ({
    jobOpportunity,
}) => {
    const intl = useIntl();

    return (
        <div className="card-header">
            <Avatar
                variant="square"
                src={jobOpportunity.jobOpportunityImgUrl || jobOpportunity.client?.logoImgUrl || PlaceholderImage}
                className="job-avatar"
            />
            <div className="header-info">
                <div className="job-title">
                    <h4>{jobOpportunity.position}</h4>
                    {jobOpportunity.isLastViewedJob && <span className="label seen">{intl.formatMessage({ id: "seen" })}</span>}
                    {jobOpportunity.application?.hasApplied && <span className="label applied">{intl.formatMessage({ id: "applied" })}</span>}
                </div>
                <div className="job-company-date-wrapper">
                    <div className="job-company">
                        <CompanyIcon className="icon" />
                        <p>{jobOpportunity.client.name}</p>
                    </div>
                    <div className="job-date">
                        <DateAddedIcon className="icon" />
                        <p>{moment(jobOpportunity.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                    </div>
                </div>
                <div className="job-location">
                    <LocationIcon className="icon" />
                    <p>{`${jobOpportunity.geographicLocations?.length ? `${intl.formatMessage({ id: "remote" })} (${jobOpportunity.geographicLocations.map(x => intl.formatMessage({ id: convertStringToLanguageKey(x) })).join(", ")})` : intl.formatMessage({ id: "remote" })}`}</p>
                </div>
            </div>
        </div>
    )
};

export default DesktopCardHeader;
