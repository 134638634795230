import { getHomePathDetails } from 'utils/helpers';
import { ROLES } from 'constants/userConstants';
import { CALENDLY_DIALOG_QUERY } from 'constants/candidateConstants';
import { MODE_EMPLOYEE_QUERY } from 'constants/employeeConstants';
import { BASE_APP_URL } from 'constants/env';

const renameMainItemAction = (menuItem, newMenuItemTitle) => {
    return { ...menuItem, TITLE: newMenuItemTitle };
};

const hideSubmenuItemsAction = (menuItem, subItemsTitles) => {
    const newItems = menuItem.ITEMS.filter(subitem => !subItemsTitles.includes(subitem.TEXT));
    return { ...menuItem, ITEMS: newItems };
};

export const getHomePath = (gotoUrl) => {
    const { agencyName, role, onboarded, ...rest } = getHomePathDetails();

    if (role === ROLES.CANDIDATE && rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/job-opportunities`, state: { gotoUrl: gotoUrl } };
    }

    if (role === ROLES.CANDIDATE && !rest.isJourneyStarted && !rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/start-journey` };
    }

    if (role === ROLES.CANDIDATE && !rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/create-profile` };
    }

    if (role === ROLES.UNASSIGNED) {
        return { pathname: `/select-a-role` };
    }

    if (role === ROLES.UNASSIGNED_CLIENT) {
        return { pathname: `/select-a-role/client` };
    }

    if (role === ROLES.UNASSIGNED_ADMIN) {
        return { pathname: `/create-company-profile` };
    }

    if (gotoUrl && gotoUrl.includes(agencyName)) {
        if (gotoUrl.includes(MODE_EMPLOYEE_QUERY)) {
            const urlObject = new URL(gotoUrl, BASE_APP_URL);
            const pathname = urlObject.pathname || '';
            return { pathname: pathname, state: { gotoUrl: gotoUrl, mode: ROLES.EMPLOYEE } }
        }

        return { pathname: gotoUrl, state: { gotoUrl: gotoUrl } }
    }

    switch (role) {
        case ROLES.CANDIDATE:
            return { pathname: `/${agencyName}/job-opportunities` };
        case ROLES.RECRUITER:
            return { pathname: `/${agencyName}/recruiter-profiles` };
        default:
            return { pathname: `/${agencyName}/dashboard` };
    }
};


const queries = [CALENDLY_DIALOG_QUERY, MODE_EMPLOYEE_QUERY];
export const getGotoUrl = (historyObject) => {
    const pathname = historyObject.location.pathname;
    const foundQuery = queries.find(query => historyObject.location?.search?.includes(query)) || '';
    return foundQuery ? `${pathname}${foundQuery}` : pathname;
};

export const filterMenuItems = (menuItems, hiddenMenuItems) => {
    const newMenuItems = menuItems.reduce((acc, menuItem) => {
        const hiddenItem = hiddenMenuItems.find(element => element.menuItemTitle === menuItem.TITLE || element.menuItemTitle === menuItem.text);

        if (hiddenItem) {
            switch (hiddenItem.action) {
                case 'hide-all':
                    break;
                case 'hide-subitems':
                    acc.push(hideSubmenuItemsAction(menuItem, hiddenItem.subItemsTitles));
                    break;
                case 'rename-mainitem':
                    acc.push(renameMainItemAction(menuItem, hiddenItem.newMenuItemTitle));
                    break;
                default:
                    break;
            }
        } else {
            acc.push(menuItem);
        }

        return acc;
    }, []);

    return newMenuItems;
}

