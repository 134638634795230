import React from 'react';
import { useIntl } from 'react-intl';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader'
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent'

import { ReactComponent as TimerIcon } from "assets/timer-icon.svg";
import { ReactComponent as IosTimerIcon } from 'assets/ios-timer-icon.svg';
import { ReactComponent as GreenListBox } from 'assets/green-list-box.svg';

import { secondsToCounterString } from 'utils/formatTimes';

import { GUIDE_QUESTIONS } from './VideoRecordingViewConstants.js';

import './VideoRecordingView.scss';

const VideoRecordingView = ({
    videoRef,
    showCountDown,
    countDownSeconds,
    timerSeconds,
    recordedVideoObjectUrl,
    hasRecordingStarted,
}) => {
    const intl = useIntl();

    return (
        <div className="video-recording-view-container">
            <PaperElement classes={["guide-questions-wrapper", "m"]}>
                <PaperHeader title={intl.formatMessage({ id: "prepared-questions-text" })} />
                <PaperContent>
                    <ul className="questions-list">
                        {GUIDE_QUESTIONS.map((question_key, index) => {
                            return (
                                <li className="question-item" key={index}>
                                    <GreenListBox className="box-icon" />
                                    <p>{intl.formatMessage({id: question_key})}</p>
                                </li>
                            )
                        })}
                    </ul>
                </PaperContent>
            </PaperElement>
            <PaperElement classes={["m"]}>
                <PaperHeader title={intl.formatMessage({ id: "video-recording" })} classes={["flex-header"]}>
                    <div className="timer-container">
                        <TimerIcon />
                        <p className="timer">{secondsToCounterString(timerSeconds)}</p>
                    </div>
                </PaperHeader>
                <PaperContent>
                    <div className="camera-container">
                        {showCountDown
                            ?
                            <div className="countdown-container">
                                <div className="countdown-items">
                                    {hasRecordingStarted && <p className="hint">{intl.formatMessage({ id: "video-recording-starts-in" })}</p>}
                                    {hasRecordingStarted && <IosTimerIcon />}
                                    {hasRecordingStarted && <p>{secondsToCounterString(countDownSeconds)}</p>}
                                </div>
                            </div>
                            : recordedVideoObjectUrl
                                ?
                                <video src={recordedVideoObjectUrl} controls>
                                    {intl.formatMessage({ id: "video-not-available" })}
                                </video>
                                :
                                <video ref={videoRef} muted controls={false}>
                                    {intl.formatMessage({ id: "video-stream-not-available" })}
                                </video>
                        }
                    </div>
                </PaperContent>
                {hasRecordingStarted && <p className="hint">*{intl.formatMessage({ id: "video-recording-hint" })}.</p>}
            </PaperElement>
        </div>
    )
};

export default VideoRecordingView;
