import { ROLES } from 'constants/userConstants';

const ROLE_OPTIONS_WHOISAVAILABLE = [
    {
        title: 'Manage remote teams',
        value: ROLES.UNASSIGNED_ADMIN,
    }
];

export const ROLE_SELECTION_OPTIONS = {
    test: ROLE_OPTIONS_WHOISAVAILABLE,
    development: ROLE_OPTIONS_WHOISAVAILABLE,
    qa: ROLE_OPTIONS_WHOISAVAILABLE,
    whoisavailable: ROLE_OPTIONS_WHOISAVAILABLE,
};
