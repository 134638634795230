import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import MyApplicationCard from './MyApplicationCard';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import NothingToShow from 'components/NothingToShow';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getStateAgencyName } from 'utils/helpers';

import './MyApplicationsList.scss';

const MyApplicationsList = ({ applications }) => {
    const intl = useIntl();
    const history = useHistory();

    const handleApplyClick = () => history.push(`/${getStateAgencyName()}/job-opportunities`);

    return (
        <div className="my-applications-list-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "my-applications" })} />
            </SectionActionsWrapper>

            {applications?.length > 0
                ? <div className="my-applications-list">
                    {applications.map(application =>
                        <MyApplicationCard
                            key={application.applicationId}
                            application={application}
                        />
                    )}
                </div>
                : <div className="no-applications-found">
                    <NothingToShow items="applications" />
                    <PrimaryButton
                        className="no-applications-apply-btn"
                        text={intl.formatMessage({ id: "apply" })}
                        handleClick={handleApplyClick}
                    />
                </div>
            }
        </div>
    );
};

export default MyApplicationsList;
