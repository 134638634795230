import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { LinearProgress } from '@material-ui/core';

import { MATCHING_FIELDS_NAME_MAPPER, MINIMUM_MATCHING_SCORE } from './MatchingDetailsConstants';

import { ReactComponent as ScoreMatchingIcon } from 'assets/score-matching-icon.svg';
import AppliedCandidateInformationIcon from 'assets/applied-candidates-information-icon.svg';

import './MatchingDetails.scss';

const MatchingDetails = ({
    matchingScores
}) => {
    const intl = useIntl();

    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({});

    const handleTooltipHover = (e, visibility) => {
        if (e) {
            const boundingRect = e.target.getBoundingClientRect();
            const topOffset = boundingRect.top;

            const yOffset = (topOffset - 240);

            let yTranslate = 0;
            const xTranslate = 0;

            if (yOffset < 0) {
                yTranslate = 260;
            }

            setPopupTransform({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
        }
        setIsShowMorePopupVisible(visibility);
    };

    return (
        <div className="matching-details-container">
            <ScoreMatchingIcon />
            <div className="skills-score-details">
                <div className="matching-score-wrapper">
                    <h4>{intl.formatMessage({ id: "matching-score" })}</h4>
                    <div className="matching-score">
                        <p className="overall-score">
                            {matchingScores.matchingScore} <small>/100</small>
                        </p>

                        <div className="applied-candidate-matching-tooltip-wrapper">
                            <span
                                className="applied-candidate-matching-icon"
                                onMouseOver={(e) => handleTooltipHover(e, true)}
                                onMouseLeave={() => handleTooltipHover(false)}
                            >
                                <img src={AppliedCandidateInformationIcon} />
                            </span>

                            <div className={`applied-candidate-matching-tooltip ${isShowMorePopupVisible ? 'show' : 'hide'}`} style={popupTransform}>
                                <h3 className="applied-candidate-matching-tooltip-title">{intl.formatMessage({ id: "candidate-matching-tooltip-title" })}</h3>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="flex-row">
                    {matchingScores.categoryWeights && Object.values(matchingScores.categoryWeights).some(weight => weight !== 0)
                        ? <p className="section-description">
                            {intl.formatMessage({ id: "scored-high-candidate" })}
                        </p>
                        : <p className="section-description">
                            {intl.formatMessage({ id: "scored-low-candidate" })}
                        </p>
                    }
                </div>
                <div className="matched-skills">
                    {matchingScores.categoryWeights && Object.keys(matchingScores.categoryWeights).map((category, i) => (
                        <>
                            {
                                matchingScores?.categoryWeights[category] >= MINIMUM_MATCHING_SCORE &&
                                <LinearProgress
                                    className="applied-candidate-progress"
                                    title={MATCHING_FIELDS_NAME_MAPPER[category]}
                                    value={matchingScores?.categoryWeights[category]}
                                    variant="determinate"
                                />
                            }
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MatchingDetails;
