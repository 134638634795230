import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { setGCLID, setReferrer } from 'utils/queryUtils';
import { isAuthenticated as isUserAuthenticated, getDisplayMode, isJourneyStarted, getAgencyRouteName, getIsInitialJourneyCompleted, getUserId, getAgencyId, isTermsAndConditions } from 'reducers';
import { showNotification } from 'actions/notificationActions';
import { setHomeInformationInitialState, fetchMainMenuInformation } from 'actions/agencyActions';
import { switchMode } from 'actions/authActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import WorkspaceMigrationDialog from 'components/Shared/WorkspaceMigrationDialog';

import { AUTH_MESSAGES } from 'constants/messageConstants';
import { ROLES } from 'constants/userConstants';
import { JOB_OPPORTUNITIES_QUERY_PARAMS } from 'constants/jobOpportunitiesConstants';
import { ENVIRONMENTS } from 'constants/env';
import { parseQueryString } from 'utils/helpers';
import { getGotoUrl } from 'utils/navigationUtils';

const isAuthenticated = (Component, requiredPermission) => {
    const Authentication = ({
        isUserAuthenticated,
        isJourneyStarted,
        isJourneyCompleted,
        isTermsAndConditionsAccepted,
        displayMode,
        showNotification,
        agencyRouteName,
        setHomeInformationInitialState,
        switchMode,
        fetchMainMenuInformation,
        userId,
        agencyId,
        ...props
    }) => {
        const history = useHistory();
        const isJobOpportunityDetailsRoute = history.location.pathname.includes('job-opportunities/');

        setReferrer(history.location?.search);
        setGCLID(history.location.search);

        const isStartJourneyRoute = history.location.pathname.includes('/start-journey');
        const isFromCandidateRegistration = history.location.state?.isFromCandidateRegistration;
        const isFromJourneyCreation = history.location.state?.comingFrom === "start-journey";
        const isJourneyRoute = history.location.pathname.includes('/create-profile');
        const isChooseUsageRoute = history.location.pathname === '/select-a-role';
        const isChooseUsageFillInformationRoute = history.location.pathname === '/select-a-role/client';
        const isCreateCompanyProfile = history.location.pathname.includes('/create-company-profile');
        const isAcceptTermsAndConditionsRoute = history.location.pathname.includes('/terms-and-conditions/accept');

        const isWhoIsAvailableEnv = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE;
        const showWorkspaceDialog = displayMode === ROLES.EMPLOYEE || displayMode === ROLES.ADMIN || displayMode === ROLES.SUPPLIER_EMPLOYEE;

        // logic for showing workspace change dialog
        const isTodayBefore19thFeb = moment().isBefore(new Date('2023-02-19'));

        useEffect(() => {
            const switchModeToRole = parseQueryString(history.location?.search).mode || history.location?.state?.mode;

            if (displayMode === ROLES.ADMIN && switchModeToRole === ROLES.EMPLOYEE) {
                setHomeInformationInitialState();
                switchMode(switchModeToRole);
                fetchMainMenuInformation(agencyId, userId, switchModeToRole);
            } else {

                if (!isUserAuthenticated && isJobOpportunityDetailsRoute) {
                    return history.push(
                        {
                            pathname: "/register/candidate",
                            state: {
                                gotoUrl: history.location.pathname,
                                jobOpportunityId: props.match.params.id,
                            }
                        })
                }

                if (!isUserAuthenticated) {
                    return history.push({
                        pathname: "/login",
                        state: {
                            gotoUrl: getGotoUrl(history)
                        },
                    });
                }

                if (requiredPermission && !requiredPermission.includes(displayMode)) {
                    if (displayMode === ROLES.CANDIDATE) {
                        history.push(`/${agencyRouteName}/`);
                    } else {
                        history.push(`/${agencyRouteName}/dashboard`);
                    }
                    showNotification(AUTH_MESSAGES.INSUFFICIENT_PERMISSIONS, NOTIFICATION_TYPES.ERROR);
                }
            }
        }, []);

        if (displayMode === ROLES.UNASSIGNED && !isChooseUsageRoute) {
            return <Redirect to={{ pathname: '/select-a-role' }} />
        }

        if (displayMode === ROLES.UNASSIGNED_CLIENT && (!isChooseUsageFillInformationRoute && !isChooseUsageRoute)) {
            return <Redirect to={{ pathname: `/select-a-role/client` }} />
        }

        if (displayMode === ROLES.UNASSIGNED_ADMIN && !isCreateCompanyProfile) {
            return <Redirect to={{ pathname: '/create-company-profile' }} />
        }

        // if user is coming from the journey creation page we do not want to redirect
        // it will redirect on its own
        if (displayMode === ROLES.CANDIDATE && isJourneyRoute && isJourneyCompleted && !isFromJourneyCreation) {
            return <Redirect to={{ pathname: `/${agencyRouteName}/job-opportunities${JOB_OPPORTUNITIES_QUERY_PARAMS}` }} />
        }

        if (displayMode === ROLES.CANDIDATE && isJourneyStarted && !isJourneyRoute && !isJourneyCompleted && !isStartJourneyRoute && !isAcceptTermsAndConditionsRoute) {
            return <Redirect to={{ pathname: `/${agencyRouteName}/create-profile` }} />
        }

        if (displayMode === ROLES.CANDIDATE && !isJourneyStarted && !isJourneyCompleted && !isStartJourneyRoute && !isAcceptTermsAndConditionsRoute || isFromCandidateRegistration) {
            return <Redirect to={{ pathname: `/${agencyRouteName}/start-journey-signup-success` }} />
        }

        if (displayMode === ROLES.CANDIDATE && !isJourneyCompleted && isJobOpportunityDetailsRoute) {
            return <Redirect to={{ pathname: `/${agencyRouteName}/create-profile/jobs/${props.match.params.id}` }} />
        }

        if (requiredPermission) {
            return isUserAuthenticated && (requiredPermission.includes(displayMode)) ?
                <>
                    <Component {...props} />
                    {!isWhoIsAvailableEnv && showWorkspaceDialog && isTodayBefore19thFeb ? <WorkspaceMigrationDialog /> : null}
                </>
                : null;
        } else {
            return isUserAuthenticated ?
                <>
                    <Component {...props} />
                    {!isWhoIsAvailableEnv && showWorkspaceDialog && isTodayBefore19thFeb ? <WorkspaceMigrationDialog /> : null}
                </>
                : null;
        }
    }

    const mapStateToProps = (state, props) => {
        const { history } = props;
        const hasHistoryState = history.location.state && history.location.state !== null;
        return {
            userId: getUserId(state),
            agencyId: getAgencyId(state),
            isUserAuthenticated: isUserAuthenticated(state),
            isJourneyStarted: isJourneyStarted(state) || hasHistoryState && Object.values(history.location.state)[0] === "start-journey",
            isJourneyCompleted: getIsInitialJourneyCompleted(state),
            isTermsAndConditionsAccepted: isTermsAndConditions(state),
            displayMode: getDisplayMode(state),
            agencyRouteName: getAgencyRouteName(state),
        }
    };

    const mapDispatchToProps = {
        showNotification,
        setHomeInformationInitialState,
        switchMode,
        fetchMainMenuInformation,
    };

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}

export default isAuthenticated;
