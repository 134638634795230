import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';

import Typography from '@material-ui/core/Typography';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import CandidateCustomerSupportRequestFormView from './CandidateCustomerSupportRequestFormView';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper/SectionActionsWrapper';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import { DESCRIPTION_MAX_LENGTH } from '../../../CustomerSupportConstants';

import './CandidateCustomerSupportRequestForm.scss';

const CandidateCustomerSupportRequestForm = ({
    requestCustomerSupport,
    accountManager
}) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={{
                name: '',
                description: ''
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'description']),
                    ...validateInputLength(values, [
                        { property: 'description', maxLength: DESCRIPTION_MAX_LENGTH },
                    ]),
                }
            }}

            onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(false);
                values = accountManager ? { ...values, accountManager } : values;
                requestCustomerSupport(values);
                resetForm();
            }}
        >
            {(props) => (
                <div className="candidate-customer-support-request-container">
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.SectionTitle sectionTitle={intl.formatMessage({ id: "customer-support" })} />
                    </SectionActionsWrapper>
                    <div className="candidate-customer-support-form-content">
                        <div>
                            <Typography className="title" variant="h4">{intl.formatMessage({ id: "how-can-we-help" })}</Typography>
                            <Typography className="helper-text" variant="h5">
                                {intl.formatMessage({ id: "how-can-we-help-content" })}
                            </Typography>
                            <CandidateCustomerSupportRequestFormView {...props} />
                        </div>
                        <div className="action-buttons">
                            <PrimaryButton
                                className="submit-request-btn"
                                text={intl.formatMessage({ id: "send" })}
                                handleClick={props.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default CandidateCustomerSupportRequestForm;
