import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';

import { connect } from 'react-redux';

import {
    updateCandidateCreateProfileJourney,
    updateCandidateJourneyCurrentStepId,
    updateCandidateJourneyIsCompleted,
    fetchCandidateJourneyPage,
    updateCandidateViewedJob,
    updateCandidatesJourneyCompletedSteps
} from 'actions/candidateActions';
import { showNotification } from 'actions/notificationActions';
import { toggleIncompleteProfileDialog } from 'actions/uiActions';

import {
    getInitialJourneyCompletedStepsIds,
    getCurrentJourneyStepId,
    getVideoInterviewQuestionsList,
    getEmployeeUploadedCv,
    getEmployeeCvInformation,
    getCompletedByAdminStepsIds,
    getIsIncompleteProfileDialogOpen,
} from 'reducers';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import CandidateProfileJourneyMobileStepper from 'components/CandidateProfileJourney/CandidateProfileJourneyMobileStepper';
import InfoMessageWithIconDialog from 'components/Shared/InfoMessageWithIconDialog';

import { ReactComponent as ClipboardPencilIcon } from 'assets/icon-clipboard-pencil.svg';

import {
    getCandidateNextStepId,
    getCandidateCurrentStepComponentAndSubrouteName,
    getHasJourneyStepsLeft,
    getCandidatePreviousStepId,
    isCvAvailable
} from './CandidateProfileJourneyUtils';

import { getStateAgencyName } from 'utils/helpers';

import { substepIds } from './CandidateProfileJourneyConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { REDIRECT_MESSAGES } from 'constants/messageConstants';
import { JOB_OPPORTUNITIES_QUERY_PARAMS } from 'constants/jobOpportunitiesConstants';
import { CALENDLY_DIALOG_QUERY } from 'constants/candidateConstants';

const CandidateProfileJourney = ({
    agencyId,
    userId,
    completedStepsIds,
    completedStepIdsByAdmin,
    isIncompleteProfileDialogOpen,
    updateCandidateCreateProfileJourney,
    updateCandidateJourneyCurrentStepId,
    updateCandidatesJourneyCompletedSteps,
    currentStepId,
    interviewQuestions,
    updateCandidateJourneyIsCompleted,
    uploadedCv,
    fetchCandidateJourneyPage,
    updateCandidateViewedJob,
    cvInformation,
    showNotification,
    toggleIncompleteProfileDialog,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const [uploads, setUploads] = useState({});
    const [navigating, setNavigating] = useState(false);

    const { StepComponent, subrouteName } = getCandidateCurrentStepComponentAndSubrouteName(currentStepId);

    useEffect(() => {
        const jobOpportunityId = history.location.state?.jobOpportunityId;

        if (jobOpportunityId) {
            updateCandidateViewedJob(agencyId, userId, jobOpportunityId);
        }

        if (typeof history.location.state == 'undefined') {
            fetchCandidateJourneyPage(agencyId, userId);
        } else {
            fetchCandidateJourneyPage(agencyId, userId, false);
        }
        window.history.replaceState(null, '');
    }, []);

    useEffect(() => {
        if (navigating === 'forward' && currentStepId === substepIds.uploadResume) {
            const skipStep = completedStepIdsByAdmin.find(x => x === substepIds.uploadResume);
            const skipStepAndUpdateIt = !completedStepsIds.find(x => x === substepIds.uploadResume) && isCvAvailable(cvInformation);
            if (skipStepAndUpdateIt || skipStep) {
                const action = updateCandidatesJourneyCompletedSteps.bind(null, agencyId, userId);
                const condition = skipStepAndUpdateIt;
                handleGoToNextStep(null, condition, action)
            };
        }
    }, [currentStepId]);

    useEffect(() => {
        if (history.location?.state?.gotoUrl?.includes(CALENDLY_DIALOG_QUERY)) {
            history.replace(`/${getStateAgencyName()}/create-profile/video-introduction${CALENDLY_DIALOG_QUERY}`)
        } else {
            history.replace(`/${getStateAgencyName()}/create-profile/${subrouteName}`);
        }
    }, [subrouteName]);

    useEffect(() => {
        if (uploadedCv.originalFileName) {
            setUploads({ type: 'file', filesAttached: [{ name: uploadedCv.originalFileName }] });
        } else {
            setUploads({});
        }
    }, [uploadedCv.originalFileName]);

    const handleGoToPreviousStep = () => {
        setNavigating('back');
        const candidatePreviousStepId = getCandidatePreviousStepId(currentStepId);

        if (candidatePreviousStepId === currentStepId) {
            return history.push(`/${getStateAgencyName()}/start-journey`);
        }

        updateCandidateJourneyCurrentStepId(candidatePreviousStepId);
    };

    // since this function is passed in other places in the primary button where we do not pass an action or a condition
    // and instead the event is passed to the function as a first argument we receive and error cannot call bind of undefined 
    // for that reason we need to ignore the first element with _ (underscore) in order to prevent the errors while we are still
    // working on the candidates journey
    const handleGoToNextStep = async (_, condition, action, redirectWithTimeOut) => {
        setNavigating('forward')
        history.replace({ state: { comingFrom: 'start-journey' } });
        const stepIds = [...new Set([...completedStepsIds, currentStepId])];
        const hasJourneyStepsLeft = getHasJourneyStepsLeft(stepIds);

        let resultAfterActionCall

        if (condition) {
            const stepData = { _id: currentStepId, date: moment() }
            const actionToCall = action.bind(null, stepData)
            resultAfterActionCall = await updateCandidateCreateProfileJourney(actionToCall, stepData, !hasJourneyStepsLeft);
        }

        // here we check if we have an error 
        // and if we do, we do not go to the next step 
        // instead we remain on the same step
        if (resultAfterActionCall && resultAfterActionCall.error) {
            return
        }

        // NOTE: We dont want to track "choiseSection".
        // NOTE2: "cvCompletion"(edit cv) step is auto marked as completed when candidate visits the edit cv page,
        // so we don't know if the candidate finishes the step for the first time, that's why we track it every time(not only the first time).

        // the function responsible for changing to the next step
        await updateCandidateJourneyCurrentStepId(getCandidateNextStepId(currentStepId));

        if (!hasJourneyStepsLeft) {
            const isJourneyCompleted = true
            updateCandidateJourneyIsCompleted(agencyId, userId, isJourneyCompleted);

            if (redirectWithTimeOut) {
                showNotification(REDIRECT_MESSAGES.CANDIDATE_JOURNEY_REDIRECT_SHORTLY, NOTIFICATION_TYPES.SUCCESS);
                setTimeout(() => {
                    history.push({
                        pathname: `/${getStateAgencyName()}/job-opportunities`,
                        state: { isFromCandidateJourney: true },
                        search: `${JOB_OPPORTUNITIES_QUERY_PARAMS}`
                    });
                }, 4000);
            } else {
                history.push({
                    pathname: `/${getStateAgencyName()}/job-opportunities`,
                    state: { isFromCandidateJourney: true },
                    search: `${JOB_OPPORTUNITIES_QUERY_PARAMS}`
                });
            }
        }
        return resultAfterActionCall;
    };

    return (
        StepComponent
            ? <>
                {
                    isMobile
                        ? <CandidateProfileJourneyMobileStepper
                            updateCandidateJourneyCurrentStepId={updateCandidateJourneyCurrentStepId}
                        />
                        : <PreviousButton text={intl.formatMessage({ id: 'previous' })} handleClick={handleGoToPreviousStep} />
                }
                <StepComponent
                    continueJourney={handleGoToNextStep}
                    discardAction={handleGoToPreviousStep}
                    agencyId={agencyId}
                    userId={userId}
                    interviewQuestions={interviewQuestions}
                    uploads={uploads}
                    setUploads={setUploads}
                />
                <InfoMessageWithIconDialog
                    isOpen={isIncompleteProfileDialogOpen}
                    handleCloseDialog={() => toggleIncompleteProfileDialog(false)}
                    title="It's great to see you back!"
                    textComponent="Take a minute to finish your profile to be matched with a remote job based on your skills and preferences."
                    iconComponent={<ClipboardPencilIcon />}
                />
            </>
            : null
    );
};

const mapStateToProps = (state) => ({
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    currentStepId: getCurrentJourneyStepId(state),
    interviewQuestions: getVideoInterviewQuestionsList(state),
    uploadedCv: getEmployeeUploadedCv(state),
    cvInformation: getEmployeeCvInformation(state),
    completedStepIdsByAdmin: getCompletedByAdminStepsIds(state),
    isIncompleteProfileDialogOpen: getIsIncompleteProfileDialogOpen(state),
});

const mapDispatchToProps = {
    updateCandidateCreateProfileJourney,
    updateCandidateJourneyCurrentStepId,
    updateCandidateJourneyIsCompleted,
    updateCandidatesJourneyCompletedSteps,
    fetchCandidateJourneyPage,
    updateCandidateViewedJob,
    showNotification,
    toggleIncompleteProfileDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileJourney);

