import React from 'react';
import { useIntl } from 'react-intl';

import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import WhatToExpect from '../WhatToExpect/WhatToExpect';

const DesktopPageWithoutJob = ({
    menuInformation,
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    agencyName,
    showContent,
}) => {
    const intl = useIntl();

    return (
        <div className="start-candidate-journey-desktop-page">
            {showContent && <div className="start-candidate-journey-wrapper">
                <div className="start-candidate-journey-container">
                    {menuInformation.companyLogoUrl &&
                        <div className="company-logo-wrapper">
                            <img
                                className="company-logo"
                                alt="Company Logo"
                                src={menuInformation.companyLogoUrl}
                            />
                        </div>
                    }
                    <PaperElement classes={["start-candidate-journey-content"]}>
                        <PaperHeader title={`${intl.formatMessage({id: 'thank-you-for-joining'})} ${agencyName}.`} classes={["start-candidate-journey-header"]} />
                        <PaperContent>
                            <p className='thank-you-for-joining-sub-header'>{intl.formatMessage({id: 'start-journey-info-text'})}</p>
                        </PaperContent>
                    </PaperElement>
                    <PaperElement classes={["start-candidate-journey-content"]}>
                        <WhatToExpect
                            getCandidateJourneyDuration={getCandidateJourneyDuration}
                            START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                        />
                    </PaperElement>
                </div>
                <PrimaryButton
                    className="start-candidate-journey-button"
                    text={intl.formatMessage({ id: 'start' })}
                    handleClick={handleStartCandidateJourney}
                />
            </div>
            }
        </div>
    )
};

export default DesktopPageWithoutJob;
