import React from 'react';
import { useIntl } from 'react-intl';

import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as StartCandidateJourneyIcon } from 'assets/start-candidate-journey-icon.svg';

import { convertStringToLanguageKey } from 'utils/languageUtils';

const WhatToExpect = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
}) => {
    const intl = useIntl();

    return (
        <>
            <PaperHeader title={intl.formatMessage({ id: "what-to-expect" })} classes={["start-candidate-journey-header"]} />
            <Typography className="start-candidate-journey-sub-header">
                {intl.formatMessage({ id: 'how-to-get-a-remote-job-in' })} <span className="highlighted-text green">{getCandidateJourneyDuration()} {intl.formatMessage({ id: 'mins' })}</span>
            </Typography>
            <div className="start-candidate-journey-steps-container">
                <ul className="start-candidate-journey-stepper">
                    {START_JOURNEY_STEPS.map((step, index) => (
                        <li key={index} className="single-step">
                            <div className="step-box">
                                <span className="box-text">{index + 1}</span>
                            </div>
                            <div className="step-content">
                                <div className="common-line">
                                    <span className="step-title">{intl.formatMessage({id: convertStringToLanguageKey(step.title)})}</span>
                                    <span className="step-duration">{step.duration}{intl.formatMessage({id: 'min'})}</span>
                                </div>
                                <span className="step-description">{intl.formatMessage({id: convertStringToLanguageKey(step.description)})}</span>
                            </div>
                        </li>
                    ))}
                </ul>

                <div className="start-candidate-journey-icon-wrapper">
                    <StartCandidateJourneyIcon className="start-candidate-journey-icon" />
                </div>
            </div>
        </>
    )
};

export default WhatToExpect;
