import React, { useState, useContext, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import moment from 'moment';

import { getCurrentJourneyStepId, getInitialJourneyCompletedStepsIds } from 'reducers';

import { exportCandidateCV, updateCandidatesJourneyCompletedSteps } from 'actions/candidateActions';
import { fetchAgencyBasicInfo } from 'actions/agencyActions';

import CvCompletion from './CvCompletion';

import CvSection from 'components/Shared/CvSection';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UploadResumeWrapperContext } from 'components/CandidateProfileJourney/Steps/UploadResumeWrapper/UploadResumeWrapper';

import { CV_INFORMATION_CATEGORIES_COUNT } from './CvCompletionPageConstants';

import './CvCompletionPage.scss';

const CvCompletionPage = ({
    currentStepId,
    completedStepsIds,
    updateCandidatesJourneyCompletedSteps,
    fetchAgencyBasicInfo,
    exportCandidateCV
}) => {
    const {
        agencyId,
        userId,
        handleNextStep,
        updateCandidateJourneyCvPage,
        employeeCvInformation,
        companyLogoUrl,
    } = useContext(UploadResumeWrapperContext);

    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (!companyLogoUrl) {
            fetchAgencyBasicInfo(agencyId)
        }
    }, [])

    useEffect(() => {
        if (!completedStepsIds.includes(currentStepId)) {
            const stepData = { _id: currentStepId, date: moment() }
            updateCandidatesJourneyCompletedSteps(agencyId, userId, stepData);
        }
    }, []);

    const scrollToRef = useRef(null);

    const enterEditMode = () => {
        setIsEditMode(true)
    }

    const exitEditMode = () => {
        setIsEditMode(false);
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView();
        }
    };

    const getCvCompletion = () => {
        const { profileInformation, ...rest } = employeeCvInformation;
        const notMissingCount = Object.keys(rest).reduce((acc, key) => {
            if ((rest[key] && rest[key].length > 0)) {
                acc += 1;
            }
            return acc;
        }, 0)

        return (notMissingCount / CV_INFORMATION_CATEGORIES_COUNT) * 100;
    };

    const handleSave = () => {
        const action = updateCandidatesJourneyCompletedSteps.bind(null, agencyId, userId);
        handleNextStep(null, true, action);
    };

    const isDataFetchComplete = employeeCvInformation.profileInformation.firstName;

    return (
        <div className='cv-completion-page-wrapper'>
            {!isDataFetchComplete
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <PaperElement classes={['cv-completion-page-container', isMobile ? 'max' : 'm']} paperContentClasses={isMobile ? [] : ["paper-content-flex"]}>
                    <CvCompletion
                        cvCompletionRate={getCvCompletion()}
                    />
                    <CvSection
                        scrollToRef={scrollToRef}
                        userId={userId}
                        agencyId={agencyId}
                        cvInformation={employeeCvInformation}
                        enterEditMode={enterEditMode}
                        exitEditMode={exitEditMode}
                        handleSave={handleSave}
                        isEditMode={isEditMode}
                        companyLogoUrl={companyLogoUrl}
                        updateCandidateJourneyCvPage={updateCandidateJourneyCvPage}
                        handleNextStep={handleNextStep}
                        exportCandidateCV={exportCandidateCV}
                    />
                </PaperElement >
            }
        </div>
    )
};

const mapStateToProps = state => ({
    currentStepId: getCurrentJourneyStepId(state),
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
});

const mapDispatchToProps = { updateCandidatesJourneyCompletedSteps, fetchAgencyBasicInfo, exportCandidateCV };

export default connect(mapStateToProps, mapDispatchToProps)(CvCompletionPage);
