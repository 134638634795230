import React from 'react';
import { useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton'
import { DialogActions } from '@material-ui/core';

import { ReactComponent as UnsupportedLocationIcon } from 'assets/unsupported-location-icon.svg';

import './UnsupportedLocationDialog.scss';

const defaultTextComponent = (intl) => (
    <p className="unsupported-location-text">
       {intl.formatMessage({ id: "information-about-your-location-text" })}
    </p>
);

const dialogActions = (intl, handleCloseDialog) => (
    <DialogSaveButton onClick={handleCloseDialog}>{intl.formatMessage({ id: "got-it" })}</DialogSaveButton>
);

const UnsupportedLocationDialog = ({
    isOpen,
    handleCloseDialog,
    locationTextComponent=null,
    dialogActionsComponent=null,
}) => {
    const intl = useIntl();

    return (
        <Dialog className="unsupported-location-dialog" open={isOpen} onClose={handleCloseDialog}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">
                    {intl.formatMessage({ id: "information-about-your-location" })}
                </Typography>
                <DialogCloseButton className="dialog-close-btn" onClick={handleCloseDialog}></DialogCloseButton>
            </MuiDialogTitle>
            <DialogContent className="dialog-content">
                <div className="unsupported-location-icon-wrapper">
                    <UnsupportedLocationIcon />
                </div>
                { locationTextComponent ? locationTextComponent() : defaultTextComponent(intl) }
            </DialogContent>
            <DialogActions className="dialog-actions-container">
                { dialogActionsComponent ? dialogActionsComponent() : dialogActions(intl, handleCloseDialog) }
            </DialogActions>
        </Dialog>
    );
};

export default UnsupportedLocationDialog;
