import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getUserId,
    getJobOpportunitiesListDataFetchState,
    getApplicationProcessInformationFetchState,
    getCandidatesPreferencesDataFetchData,
    getIsJourneyCompleted,
    // getIsCandidateWelcomePopupOpen,
} from 'reducers';

import {
    fetchCandidatePersonalInformation,
    fetchCandidateApplicationProcessInformation,
    fetchCandidatePreferences,
} from 'actions/candidateActions';
import { fetchAgencyIndexedJobs } from 'actions/jobOpportunityActions';
import { toggleCandidateWelcomePopup } from 'actions/uiActions';

import Jobs from './Jobs';
import ApplicationProcessBanner from 'components/Shared/ApplicationProcessBanner';
// import CandidateWelcomePopup from 'components/Shared/CandidateWelcomePopup';

import { CircularProgress } from '@material-ui/core';

const JobOpportunities = ({
    match,
    userId,
    agencyId,
    fetchCandidatePersonalInformation,
    dataFetchState,
    applicationProcessInformationFetchState,
    candidatePreferencseFetchState,
    fetchAgencyIndexedJobs,
    fetchCandidateApplicationProcessInformation,
    fetchCandidatePreferences,
    isFromCandidateJourney,
    // isCandidateWelcomePopupOpen,
    // toggleCandidateWelcomePopup,
}) => {
    useEffect(() => {
        fetchCandidatePersonalInformation(agencyId, userId);
        fetchAgencyIndexedJobs(agencyId, userId);
        fetchCandidateApplicationProcessInformation(agencyId, userId);
        fetchCandidatePreferences(agencyId, userId);
    }, []);

    // const handleCloseCandidateWelcomeDialog = () => toggleCandidateWelcomePopup(false);

    const showWelcomeBack = !isFromCandidateJourney;

    return (
        <div className="job-opportunities-wrapper global-full-viewport-height global-relative">
            {dataFetchState?.isDataFetching || applicationProcessInformationFetchState?.isDataFetching || candidatePreferencseFetchState?.isDataFetching
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <>
                    <ApplicationProcessBanner showWelcomeBack={showWelcomeBack} />
                    <Jobs
                        userId={userId}
                        agencyId={agencyId}
                        match={match}
                        isAfterJourney={true}
                        isFromCandidateJourney={isFromCandidateJourney}
                    />
                </>
            }
            {/* { isCandidateWelcomePopupOpen && <CandidateWelcomePopup handleClick={handleCloseCandidateWelcomeDialog} /> } */}
        </div>
    )
};

const mapStateToProps = state => ({
    userId: getUserId(state),
    dataFetchState: getJobOpportunitiesListDataFetchState(state),
    applicationProcessInformationFetchState: getApplicationProcessInformationFetchState(state),
    candidatePreferencseFetchState: getCandidatesPreferencesDataFetchData(state),
    isFromCandidateJourney: getIsJourneyCompleted(state),
    // isCandidateWelcomePopupOpen: getIsCandidateWelcomePopupOpen(state),
});

const mapDispatchToProps = {
    fetchCandidatePersonalInformation,
    fetchAgencyIndexedJobs,
    fetchCandidateApplicationProcessInformation,
    fetchCandidatePreferences,
    toggleCandidateWelcomePopup
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOpportunities);
