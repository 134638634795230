import React from 'react'
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as CalendarIcon } from 'assets/applied-candidate-calendar.svg';
import { ReactComponent as NoteIcon } from 'assets/note-icon.svg';

import { candidateStatusMapper } from 'utils/applicationUtils';
import { getStateAgencyName } from 'utils/helpers';
import { areAllDatesInThePast } from 'utils/dateUtils';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import { DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';
import { INTERVIEW_INVITATION_STATUSES, INTERVIEW_INVITATION_CONTRIBUTORS } from 'constants/interviewInvitationConstants';

const MyApplicationCard = ({ application }) => {
    const intl = useIntl();
    const history = useHistory();

    const isRespondButtonVisible = application.interviewInvitation?.status === INTERVIEW_INVITATION_STATUSES.PENDING
        && (Array.isArray(application.interviewInvitation?.interviewTimeSlots)
            ? !areAllDatesInThePast(application.interviewInvitation?.interviewTimeSlots?.map(x => x.date))
            : false)
        && application.interviewInvitation?.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER;;

    const applicationLink = `/${getStateAgencyName()}/my-applications/${application.applicationId}`;

    const handleRespondClick = () => history.push(applicationLink);

    return (
        <Link
            className="my-application-card-link"
            to={applicationLink}
        >
            <div className="my-application-card-wrapper">
                <div className={`application-status-label ${candidateStatusMapper(application.status)?.toLowerCase().split(' ').join('-')}`} >
                    <span>{intl.formatMessage({ id: convertStringToLanguageKey(candidateStatusMapper(application.status)) })}</span>
                </div>

                <div className="position-date-wrapper">
                    <p className="position">{application.position}</p>
                    <div className="date-wrapper">
                        <CalendarIcon />
                        <span>{moment(application.applicationDate).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}</span>
                    </div>
                </div>
                <p className="company">{application.company}</p>
                {application.feedback?.comment &&
                    <>
                        <Divider className="section-divider" />
                        <div className="feedback-wrapper">
                            <div className="feedback-header">
                                <NoteIcon />
                                <h4>{intl.formatMessage({ id: "feedback" })}</h4>
                            </div>
                            <div className="feedback-body">
                                {application.feedback.comment}
                            </div>
                        </div>
                    </>
                }
                {isRespondButtonVisible &&
                    <PrimaryButton
                        className="application-respond-button"
                        text={intl.formatMessage({ id: "respond" })}
                        handleClick={handleRespondClick}
                    />
                }
            </div>
        </Link>
    );
};

export default MyApplicationCard;
