import { Box, CircularProgress } from '@material-ui/core';
import { ReactComponent as CulturalFitRejectionIcon } from 'assets/cultural-fit-rejection-icon.svg';

import { CANDIDATE_REJECTION_REASONS_KEYS } from 'constants/candidateConstants';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { calculateDaysLeft } from 'utils/formatTimes';

export const getRejectionBannerInfo = (intl, rejectionReason, rejectionDate, handleRequestNewReview) => {
    const totalDaysUntilRequestNewReview = 180;
    const { daysLeft, daysLeftInPercentage } = calculateDaysLeft(rejectionDate, totalDaysUntilRequestNewReview);

    switch (rejectionReason) {
        case CANDIDATE_REJECTION_REASONS_KEYS.LACK_OF_CULTURAL_FIT:
        case CANDIDATE_REJECTION_REASONS_KEYS.HARD_REJECT:
        case CANDIDATE_REJECTION_REASONS_KEYS.OTHER:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: intl.formatMessage({ id: "profile-not-approved" }),
                message: intl.formatMessage({ id: "profile-not-approved-message" })
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.LACKING_SKILLS:
            return {
                icon: (
                    <Box className="box-wrapper">
                        <CircularProgress className="circular-progress background" variant="determinate" size={66} thickness={3} value={100} />
                        <CircularProgress className="circular-progress foreground" variant="determinate" size={66} thickness={3} value={daysLeftInPercentage} />

                        <Box className="days-left-box">
                            <p className="days-left">{daysLeft}</p>
                            <p className>{daysLeft === 1 ? intl.formatMessage({ id: "day" }) : intl.formatMessage({ id: "days" })}</p>
                        </Box>
                    </Box>
                ),
                title: `${intl.formatMessage({ id: "you-have" })} ${daysLeft === 1 ? `${daysLeft} ${intl.formatMessage({ id: "day-left" })}` : `${daysLeft} ${intl.formatMessage({ id: "days-left" }).toLowerCase()}`} ${intl.formatMessage({ id: "to-update-your-profile" })}`,
                message: intl.formatMessage({ id: "update-profile-message" }),
                action: (
                    <PrimaryButton
                        text={intl.formatMessage({ id: "request-new-review" })}
                        handleClick={handleRequestNewReview}
                        disabled={daysLeft > 0}
                    />
                )
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.UNREASONABLE_REMUNERATION:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: intl.formatMessage({ id: "profile-not-approved" }),
                message: intl.formatMessage({ id: "profile-unreasonable-remuneration-message" }),
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.UNSUITABLE_LOCATION:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: intl.formatMessage({ id: "profile-not-approved" }),
                message: intl.formatMessage({ id: "profile-unsuitable-location-message" }),
            };
        default:
            return {};
    }
};
