import GuestLayout from 'layouts/Guest';
import ProdGuestLayout from 'layouts/ProdGuestLayout';

import preval from 'preval.macro';
import MotionSoftwareLoginBackground from 'assets/mountain-no-border.png';
import WhoIsAvailableLogo from 'assets/Logo.js';
import WhoIsAvailableLogoWhite from 'assets/LogoWhite.js';
import WhoIsAvailableLoginBackground from 'assets/login-background-WIA.svg';

const SERVER_ADDRESSES = {
    test: 'http://localhost:5000',
    development: 'http://localhost:5000',
    qa: 'https://wia.dev.softuni.bg',
    whoisavailable: 'https://api.wia.softuni.bg',
};

const APP_ADDRESS = {
    test: 'http://localhost:3000',
    development: 'http://localhost:3000',
    qa: 'https://softuni-wia-dev.web.app',
    whoisavailable: 'https://wia.softuni.bg',
};

const FIREBASE_CONFIGS = {
    test: {
        apiKey: 'AIzaSyBNsCB1Pdd1ESpRi-cBN7i21dgPuaU7bO4',
        authDomain: 'who-is-available-qa.firebaseapp.com',
        databaseURL: 'https://who-is-available-qa.firebaseio.com',
        projectId: 'who-is-available-qa',
        storageBucket: 'who-is-available-qa.appspot.com',
        messagingSenderId: '913904170245',
        appId: '1:913904170245:web:8a35101b8cd6d708f9736c'
    },
    development: {
        apiKey: "AIzaSyDzMC9S5eNVagYotonFwRxlFnOsGGt852c",
        authDomain: "softuni-wia-dev.firebaseapp.com",
        projectId: "softuni-wia-dev",
        storageBucket: "softuni-wia-dev.appspot.com",
        messagingSenderId: "771770069690",
        appId: "1:771770069690:web:13445f275fd91142418883",
        measurementId: "G-654646W6PF"

    },
    qa: {
        apiKey: "AIzaSyDzMC9S5eNVagYotonFwRxlFnOsGGt852c",
        authDomain: "softuni-wia-dev.firebaseapp.com",
        projectId: "softuni-wia-dev",
        storageBucket: "softuni-wia-dev.appspot.com",
        messagingSenderId: "771770069690",
        appId: "1:771770069690:web:13445f275fd91142418883",
        measurementId: 'G-654646W6PF'
    },
    whoisavailable: {
        apiKey: "AIzaSyCfdxr3VvjqVhA4-kJ9W0YvMjjmvEAwxbU",
        authDomain: "softuni-wia-prod.firebaseapp.com",
        projectId: "softuni-wia-prod",
        storageBucket: "softuni-wia-prod.appspot.com",
        messagingSenderId: "565064583127",
        appId: "1:565064583127:web:51f61be58df55fc58f2d92",
        measurementId: "G-RCX90VPKYV"
    },
};

const APP_TRADEMARKS = {
    test: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://motion-software.com/' },
    development: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://motion-software.com/' },
    qa: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://motion-software.com/' },
    whoisavailable: { LOGO: WhoIsAvailableLogo, LOGO_WHITE: WhoIsAvailableLogoWhite, TITLE: 'WIA', LOGO_HREF: 'https://wia.softuni.bg/' },
};

const APP_LOGIN_BACKGROUNDS = {
    test: MotionSoftwareLoginBackground,
    development: MotionSoftwareLoginBackground,
    qa: MotionSoftwareLoginBackground,
    whoisavailable: WhoIsAvailableLoginBackground,
};

const SUPPORT_EMAILS = {
    test: 'support@wiasaas.com',
    development: 'support@wiasaas.com',
    qa: 'support@wiasaas.com',
    whoisavailable: 'support@wiasaas.com',
};

export const ENVIRONMENTS = {
    DEVELOPMENT: 'development',
    QA: 'qa',
    WHOISAVAILABLE: 'whoisavailable',
};

const CANDIDATE_SIGN_UP_LAYOUTS = {
    development: ProdGuestLayout,
    qa: ProdGuestLayout,
    whoisavailable: GuestLayout,
}

const DEFAULT_AGENCY_IDS = {
    development: '616e7d134ff6dd0f7443d8d9',
    qa: '64b8f02a8843cf8f64bcf432',
    softuni: '60a28d6d6d0d111208a2a1b4',
    unit_testing: '616e7d134ff6dd0f7443d8d9',
};

const SECRET_MANAGER_KEYS = {
    integration_testing: '771770069690',
    development: '771770069690',
    qa: '771770069690',
    saas: '565064583127',
};

export const IS_WHOISAVAILABLE_ENV = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE || process.env.NODE_ENV === ENVIRONMENTS.WHOISAVAILABLE;

export const MOTION_SOFTWARE_AGENCY_NAME = 'Motion Software';
export const MOTION_SOFTWARE_WEBSITE_URL = 'https://motion-software.com';
export const APP_VERSION = preval`module.exports = process.env.REACT_APP_VERSION + '.' + Math.trunc(Date.now() / 1000);`;
export const BASE_URL = SERVER_ADDRESSES[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const BASE_APP_URL = APP_ADDRESS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const FIREBASE_CONFIG = FIREBASE_CONFIGS[process.env.REACT_APP_ENV || process.env.NODE_ENV]
export const BASE_APP_TRADEMARK = APP_TRADEMARKS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const APP_LOGIN_BACKGROUND = APP_LOGIN_BACKGROUNDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const SUPPORT_EMAIL = SUPPORT_EMAILS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const CANDIDATE_SIGN_UP_LAYOUT = CANDIDATE_SIGN_UP_LAYOUTS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const DEFAULT_AGENCY_ID = DEFAULT_AGENCY_IDS[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const SECRET_MANAGER_KEY = SECRET_MANAGER_KEYS[process.env.NODE_ENV];
