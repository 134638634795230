import React from 'react';

import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { getWelcomeScreen } from './onboardingConstants';

import { ReactComponent as WelcomeImage } from 'assets/clientOnboarding/welcome.svg';

import './Onboarding.scss';

const Welcome = ({
    agencyId,
    userId,
    setIsStepperActive,
    displayName,
    role,
    agencyName,
    menuInformation,
    updateHomeInformation,
    gotoUrl,
}) => {
    const handleStart = () => setIsStepperActive(true);

    const welcomeScreen = getWelcomeScreen(role, agencyName);

    const handleSkip = () => {
        updateHomeInformation(agencyId, userId, { isOnboardingPassed: true }, gotoUrl, role);
    };
  
    return (
        <div className="onboarding-wrapper step-welcome">
            { menuInformation.companyLogoUrl && <img src={menuInformation.companyLogoUrl} alt="company logo" className="company-logo"/> }
            <div className="onboarding-banner-content welcome-banner-content">
                <Typography className="banner-header" variant="h4">Welcome, {displayName}!</Typography>
                {welcomeScreen}
                <WelcomeImage className="banner-image"/>
            </div>
            <div className="buttons-container">
                <PrimaryButton text="Let's get Started" handleClick={handleStart} />
                <SecondaryButton className="link-button" text="I don't want help" handleClick={handleSkip} />
            </div>
        </div>
    )
};

export default Welcome;
