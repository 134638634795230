import React from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as UploadCloudIcon } from 'assets/upload-cv-cloud-icon.svg';

import UploadFiles from '../UploadFiles';

import './CandidateJourneyUploadFile.scss';

const CandidateJourneyUploadFile = ({
    buttonRef,
    handleOnInputChange,
    inputFilesRef,
    getRootProps,
    uploads,
    setUploads,
    setInputFilesRefState,
}) => {
    const intl = useIntl();

    return (
        <div className="candidate-journey-upload-file" {...getRootProps()}>
            <input className="pdf-input"
                hidden
                type="file"
                ref={inputFilesRef}
                onChange={handleOnInputChange}
            />
            <div ref={buttonRef} className="import-pdf-container">
                <span>
                    <UploadCloudIcon className="cloud-icon" />
                </span>
                <span>{intl.formatMessage({ id: "drag-and-drop" })}</span>
                <span>{intl.formatMessage({ id: "your-files-or" })}<button
                    className='browse-button'
                    type="file"
                    onClick={() => {
                        inputFilesRef.current.click();
                        setInputFilesRefState(inputFilesRef);
                    }}
                >
                    {intl.formatMessage({ id: "browse" })}
                </button>
                </span>
            </div>
            <p className="allowed-formats-text">.doc, .docx, .pdf, .txt</p>
            {uploads?.type &&
                <UploadFiles
                    name="filesAttached"
                    filesAttached={uploads?.type === 'file' ? uploads.filesAttached : uploads.attachedLink}
                    setUploads={setUploads}
                />
            }
        </div>
    );
};

export default CandidateJourneyUploadFile;
