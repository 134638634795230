import React from 'react';
import { useIntl } from 'react-intl';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import ErrorIcon from '@material-ui/icons/Error';

import './UnfinishedProfilePopup.scss';

const UnfinishedProfilePopup = ({handleClick}) => {
    const intl = useIntl();

    return (
        <div className="unfinished-profile-warning">
            <div className="inner">
                <div className="header">
                    <ErrorIcon className="warning-icon" />
                    <p>{intl.formatMessage({ id: "oops" })}.</p>
                </div>
                <div className="body">
                    <p className="message">{intl.formatMessage({ id: "oops-message" })}.</p>
                </div>
                <PrimaryButton handleClick={handleClick} className="finishi-profile-button" text={intl.formatMessage({ id: "finish-profile" })}/>
            </div>
        </div>
    )
}

export default UnfinishedProfilePopup
