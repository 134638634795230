import React, { createContext, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
    getAgencyId,
    getCurrentUser,
    getJobOpportunitiesListDataFetchState,
    getClientNotifications,
    getDisplayMode,
    getClientId,
    isAdmin,
    getJobOpportunitiesContractorJobsTotalCount,
    getJobOpportunitiesFullTimeJobsTotalCount,
} from 'reducers';

import {
    fetchClientJobOpportunities,
    fetchJobOpportunities,
    exportJobOpportunities,
    exportClientJobOpportunities
} from 'actions/jobOpportunityActions';
import { fetchClientNotifications, fetchClients, updateClientNotificationsSeenList } from 'actions/clientActions';

import JobTypeBox from './JobTypeBox';
import JobsList from './JobsList';
import JobNotifications from './SharedComponents/JobNotifications';
import JobCalendar from './SharedComponents/JobCalendar';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as WaveHand } from 'assets/jobs-dashboard-greeting-hand.svg';

import { parseQueryString, updateQuery } from 'utils/helpers';
import { encodeFilters, mapQueryToFilters } from './JobsDashboardUtils';

import { INITIAL_JOBS_DASHBOARD_FILTERS, DEFAULT_PAGE, DEFAULT_LIMIT } from './JobsDashboardConstants';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';

import './JobsDashboard.scss';

const JobsDashboardContext = createContext();
const { Provider } = JobsDashboardContext;

const JobsDashboard = ({
    agencyId,
    clientId,
    user,
    fetchJobOpportunities,
    fetchClientJobOpportunities,
    fetchClientNotifications,
    fetchClients,
    updateClientNotificationsSeenList,
    notifications,
    dataFetchState,
    displayMode,
    isAdmin,
    children,
    exportJobOpportunities,
    exportClientJobOpportunities,
}) => {
    const { id } = useParams();
    const isFromClientPage = Boolean(id);

    const history = useHistory();
    const selectedJobType = history.location.state?.jobType;
    const [filters, setFilters] = useState(INITIAL_JOBS_DASHBOARD_FILTERS[displayMode]);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [hasMoreJobs, setHasMoreJobs] = useState(true);
    const [areFiltersParsed, setAreFiltersParsed] = useState(false);
    const [isInitialFetch, setIsInitialFetch] = useState(true);
    const [showJobListLoader, setShowJobListLoader] = useState(false);

    useEffect(() => {
        if (areFiltersParsed) {
            setShowJobListLoader(true);
            if (isFromClientPage) {
                fetchClientJobOpportunities(agencyId, id, { ...encodeFilters(filters), limit: DEFAULT_LIMIT, page }, isInitialFetch, true).then(() => {
                    setShowJobListLoader(false);
                    setPage(DEFAULT_PAGE + 1);
                    setHasMoreJobs(true);
                    setIsInitialFetch(false);
                });
            } else {
                fetchJobOpportunities(agencyId, { ...encodeFilters(filters), limit: DEFAULT_LIMIT, page }, isInitialFetch, true).then(() => {
                    setShowJobListLoader(false);
                    setPage(DEFAULT_PAGE + 1);
                    setHasMoreJobs(true);
                    setIsInitialFetch(false);
                });
            }
        }
    }, [filters, areFiltersParsed]);

    useEffect(() => {
        const queryString = history.location.search;
        const historyState = history.location.state;
        const parsedQuery = parseQueryString(queryString);

        setPage(DEFAULT_PAGE);
        if (Object.keys(parsedQuery).length > 0) {
            isAdmin
                ? setFilters(prevState => ({
                    ...mapQueryToFilters(parsedQuery),
                    searchTerm: prevState.searchTerm,
                    technologySearchTerm: prevState.technologySearchTerm,
                }))
                : setFilters(mapQueryToFilters(parsedQuery))

        } else if (!historyState?.isReset && (filters.searchTerm || filters.technologySearchTerm)) {
            setFilters(prevState => ({
                ...INITIAL_JOBS_DASHBOARD_FILTERS[displayMode],
                searchTerm: prevState.searchTerm,
                technologySearchTerm: prevState.technologySearchTerm,
            }));
        } else {
            setFilters(INITIAL_JOBS_DASHBOARD_FILTERS[displayMode]);
        }
        setAreFiltersParsed(true);
    }, [history.location.search, history.location.state]);

    const handleFetchMoreJobOpportunities = async (jobs, jobsTotalCount) => {
        if (jobs.length >= jobsTotalCount) {
            setHasMoreJobs(false);
            return;
        }

        if (isFromClientPage) {
            await setPage((prev) => prev + 1);
            fetchClientJobOpportunities(agencyId, id, { ...encodeFilters(filters), limit: DEFAULT_LIMIT, page}, false);
        } else {
            await setPage((prev) => prev + 1);
            fetchJobOpportunities(agencyId, { ...encodeFilters(filters), limit: DEFAULT_LIMIT, page}, false);
        }
    };

    const handleSingleSelectFilterChange = (filterName, value) => {
        const filterObj = filters[filterName] !== value
            ? { [filterName]: value }
            : { [filterName]: '' };

        const updatedQuery = updateQuery(history.location.search, filterObj);

        // reset the jobs on filter change
        setIsInitialFetch(true);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleSearchFieldChange = (e, fieldName) => {
        const text = e.target.value;
        setPage(DEFAULT_PAGE);
        setFilters(oldState => ({ ...oldState, [fieldName]: text }));
    };

    const context = {
        agencyId,
        clientId,
        user,
        fetchClientNotifications,
        fetchClients,
        updateClientNotificationsSeenList,
        notifications,
        dataFetchState,
        selectedJobType,
        filters,
        setFilters,
        showJobListLoader,
        hasMoreJobs,
        handleFetchMoreJobOpportunities,
        handleSingleSelectFilterChange,
        handleSearchFieldChange,
        history,
        isFromClientPage,
        isAdmin,
        exportJobOpportunities,
        exportClientJobOpportunities,
    };

    return (
        <Provider value={context} className="jobs-dashboard-content-wrapper">
            {children}
        </Provider>
    );
};

const JobsDashboardClientView = ({
    children,
}) => {
    const {
        agencyId,
        clientId,
        user,
        fetchClientNotifications,
        updateClientNotificationsSeenList,
        notifications,
        dataFetchState,
    } = useContext(JobsDashboardContext);

    useEffect(() => {
        fetchClientNotifications(agencyId, clientId);
    }, []);

    useEffect(() => {
        const unseenNotifications = notifications?.filter(x => {
            return x.seenBy.indexOf(user._id) === -1;
        });

        if (unseenNotifications?.length > 0) {
            updateClientNotificationsSeenList(agencyId, user._id, unseenNotifications);
        }
    }, [notifications]);

    return (
        <>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} className="page-loader" disableShrink />
                : <>
                    {children}
                </>
            }
        </>
    );
};

const JobsDashboardAdminView = ({
    children,
}) => {
    const {
        agencyId,
        dataFetchState,
        fetchClients,
    } = useContext(JobsDashboardContext);

    useEffect(() => {
        fetchClients(agencyId);
    }, []);

    return (
        <>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} className="page-loader" disableShrink />
                : <>
                    {children}
                </>
            }
        </>
    )
};

const JobsDashboardSupplierAdminView = ({
    children,
}) => {
    const { dataFetchState } = useContext(JobsDashboardContext);

    return (
        <>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={50} className="page-loader" disableShrink />
                : <>
                    {children}
                </>
            }
        </>
    )
};

const JobsDashboardJobTypeBoxes = ({
    displayMode,
}) => {
    const intl = useIntl();

    const {
        handleSingleSelectFilterChange,
        filters,
    } = useContext(JobsDashboardContext);

    return (
        <div className="job-type-filter-boxes-container">
            <JobTypeBox
                selectJobsTab={() => handleSingleSelectFilterChange('jobType', JOB_OPPORTUNITIES_TYPES.CONTRACT)}
                jobType={JOB_OPPORTUNITIES_TYPES.CONTRACT}
                jobTypeText={intl.formatMessage({ id: "contractor-jobs" })}
                filters={filters}
                displayMode={displayMode}
                dataSelector={getJobOpportunitiesContractorJobsTotalCount}
            />
            <JobTypeBox
                selectJobsTab={() => handleSingleSelectFilterChange('jobType', JOB_OPPORTUNITIES_TYPES.FULL_TIME)}
                jobType={JOB_OPPORTUNITIES_TYPES.FULL_TIME}
                jobTypeText={intl.formatMessage({ id: "full-time-jobs" })}
                filters={filters}
                displayMode={displayMode}
                dataSelector={getJobOpportunitiesFullTimeJobsTotalCount}
            />
        </div>
    );
};

const JobsDashboardGreetings = ({ }) => {
    const { user } = useContext(JobsDashboardContext);

    return (
        <>
            <h2 className="client-jobs-dashboard-greetings">Welcome {user.name?.split(" ")[0] || ''}!</h2>
            <p className="client-jobs-dashboard-sub-greetings">Let's look at your jobs and find the best applicants <WaveHand /></p>
        </>
    )
};

const JobsDashboardJobsList = ({
    getPathToJobDetails,
}) => {
    const {
        agencyId,
        filters,
        showJobListLoader,
        hasMoreJobs,
        handleFetchMoreJobOpportunities,
        handleSingleSelectFilterChange,
        handleSearchFieldChange,
        user,
        selectedJobType,
        clientId,
        isFromClientPage,
        isAdmin,
        exportJobOpportunities,
        exportClientJobOpportunities,
    } = useContext(JobsDashboardContext);

    const handleExportJobs = () => {
        (isFromClientPage && isAdmin) || (clientId && !isAdmin)
            ? exportClientJobOpportunities(agencyId, clientId, encodeFilters(filters))
            : exportJobOpportunities(agencyId, encodeFilters(filters))
    };

    return (
        <JobsList
            agencyId={agencyId}
            filters={filters}
            showJobListLoader={showJobListLoader}
            hasMoreJobs={hasMoreJobs}
            handleFetchMoreJobOpportunities={handleFetchMoreJobOpportunities}
            handleSingleSelectFilterChange={handleSingleSelectFilterChange}
            handleSearchFieldChange={handleSearchFieldChange}
            user={user}
            selectedJobType={selectedJobType}
            clientId={clientId}
            getPathToJobDetails={getPathToJobDetails}
            handleExport={handleExportJobs}
        />
    )
};

const JobsDashboardCalendar = ({ }) => {
    const { agencyId, clientId } = useContext(JobsDashboardContext);

    return <JobCalendar agencyId={agencyId} clientId={clientId} />
};

const JobsDashboardNotifications = ({ }) => {
    const { notifications } = useContext(JobsDashboardContext);

    return <JobNotifications notifications={notifications} />
};

JobsDashboard.JobsDashboardClientView = JobsDashboardClientView;
JobsDashboard.JobsDashboardAdminView = JobsDashboardAdminView;
JobsDashboard.JobsDashboardSupplierAdminView = JobsDashboardSupplierAdminView;

JobsDashboard.JobsDashboardJobTypeBoxes = JobsDashboardJobTypeBoxes;
JobsDashboard.JobsDashboardGreetings = JobsDashboardGreetings;
JobsDashboard.JobsDashboardJobsList = JobsDashboardJobsList;
JobsDashboard.JobsDashboardCalendar = JobsDashboardCalendar;
JobsDashboard.JobsDashboardNotifications = JobsDashboardNotifications;

const mapDispatchToProps = {
    fetchJobOpportunities,
    fetchClients,
    fetchClientNotifications,
    updateClientNotificationsSeenList,
    fetchClientJobOpportunities,
    exportJobOpportunities,
    exportClientJobOpportunities,
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    user: getCurrentUser(state),
    displayMode: getDisplayMode(state),
    notifications: getClientNotifications(state),
    dataFetchState: getJobOpportunitiesListDataFetchState(state),
    isAdmin: isAdmin(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsDashboard);
