import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth, * as fromAuth from './authReducer';
import employee, * as fromEmployee from './employeeReducer/employeeReducer';
import notification, * as fromNotification from './notificationReducer';
import agency, * as fromAgency from './agencyReducer/agencyReducer';
import ui, * as fromUi from './uiReducer';
import menu, * as fromMenu from './menuReducer';
import clients, * as fromClients from './clientsReducer';
import client, * as fromClient from './clientReducer/clientReducer';
import staticData, * as fromStaticData from './staticDataReducer';
import search, * as fromSearch from './searchReducer';
import applicationLoader, * as fromApplicationLoader from './applicationLoaderReducer';
import supplier, * as fromSupplier from './suppliersReducer';
import contracts, * as fromContracts from './contractsReducer';
import publicHolidays, * as fromPublicHolidays from './publicHolidaysReducer';
import recruiters, * as fromRecruiters from './recruitersReducer';
import recruiter, * as fromRecruiter from './recruiterReducer';
import recruiterProfiles, * as fromRecruiterProfiles from './recruiterProfilesReducer';
import jobOpportunities, * as fromJobOpportunities from './jobOpportunityReducer';
import departments, * as fromDepartments from './departmentReducer';
import videoInterview, * as fromVideoInterview from './videoInterviewReducer';
import applications, * as fromApplications from './applicationsReducer';
import adminDashboard, * as fromAdminDashboard from './adminDashboardReducer';
import manager, * as fromManager from './managerReducer';
import historicalNotes, * as fromHistoricalNotes from './historicalNotesReducer';
import exploreTalents, * as fromExploreTalents from './exploreTalentsReducer';
import sectionsLoaders, * as fromSectionsLoaders from './sectionsLoadersReducer';
import coachingOpportunities, * as fromCoachingOpportunities from './coachingOpportunitiesReducer';
import businessTrips, * as fromBusinessTrips from './businessTripsReducer';
import reports, * as fromReports from './reportsReducer';
import language, * as fromLanguage from './languageReducer';

const createRootReducer = history => combineReducers({
    router: connectRouter(history),
    auth,
    employee,
    notification,
    agency,
    client,
    clients,
    staticData,
    search,
    applicationLoader,
    supplier,
    contracts,
    publicHolidays,
    recruiters,
    recruiter,
    recruiterProfiles,
    jobOpportunities,
    departments,
    ui,
    videoInterview,
    applications,
    adminDashboard,
    manager,
    historicalNotes,
    exploreTalents,
    sectionsLoaders,
    coachingOpportunities,
    menu,
    businessTrips,
    reports,
    language,
});

export default createRootReducer;

// Auth
export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth);
export const isAdmin = state => fromAuth.isAdmin(state.auth);
export const isClient = state => fromAuth.isClient(state.auth);
export const isEmployee = state => fromAuth.isEmployee(state.auth);
export const isSupplierAdmin = state => fromAuth.isSupplierAdmin(state.auth);
export const getRoles = state => fromAuth.getRoles(state.auth);
export const getEmail = state => fromAuth.getEmail(state.auth);
export const getDisplayName = state => fromAuth.getDisplayName(state.auth);
export const getCurrentUser = state => fromAuth.getCurrentUser(state);
export const getAgencyId = state => fromAuth.getAgencyId(state.auth);
export const getDisplayMode = state => fromAuth.getDisplayMode(state.auth);
export const getUserId = state => fromAuth.getUserId(state.auth);
export const getIdToken = state => fromAuth.getIdToken(state.auth);
export const isAdminMode = state => fromAuth.isAdminMode(state.auth);
export const isCandidateMode = state => fromAuth.isCandidateMode(state.auth);
export const isClientMode = state => fromAuth.isClientMode(state.auth);
export const isEmployeeMode = state => fromAuth.isEmployeeMode(state.auth);
export const isSupplierAdminMode = state => fromAuth.isSupplierAdminMode(state.auth);
export const isRecruiterMode = state => fromAuth.isRecruiterMode(state.auth);
export const isOnboarded = state => fromAuth.isOnboarded(state.auth);
export const isClientsInvoicingStatusDone = state => fromAuth.isClientsInvoicingStatusDone(state.auth);
export const isJourneyStarted = state => fromAuth.isJourneyStarted(state.auth);
export const isSupplierEmployeeMode = state => fromAuth.isSupplierEmployeeMode(state.auth);
export const getAccess = state => fromAuth.getAccess(state.auth);
export const isTermsAndConditions = state => fromAuth.isTermsAndConditions(state.auth);
export const getUserImage = state => fromAuth.getUserImage(state.auth);
export const getUserPosition = state => fromAuth.getUserPosition(state.auth);
export const getHasNeededEvrotrustInfo = state => fromAuth.getHasNeededEvrotrustInfo(state.auth);
export const getPreselectedRole = state => fromAuth.getPreselectedRole(state.auth);

// Employee
export const getEmployeeEducationList = state => fromEmployee.getEmployeeEducationList(state.employee);
export const getPerformanceReviews = state => fromEmployee.getPerformanceReviews(state.employee);
export const getOverallPerformance = state => fromEmployee.getOverallPerformance(state.employee);
export const getProfileSkills = state => fromEmployee.getProfileSkills(state.employee);
export const getEmployeeLanguages = state => fromEmployee.getEmployeeLanguages(state.employee);
export const getTrackedDays = state => fromEmployee.getTrackedDays(state.employee);
export const getEmployeePersonalProjectsList = state => fromEmployee.getEmployeePersonalProjectsList(state.employee);
export const getEmployeeCurrentPersonalProject = state => fromEmployee.getEmployeeCurrentPersonalProject(state.employee);
export const getPersonalProjectTemplatesList = state => fromEmployee.getPersonalProjectTemplatesList(state.employee);
export const getCertificates = state => fromEmployee.getCertificates(state.employee);
export const getEmploymentInformation = state => fromEmployee.getEmploymentInformation(state.employee);
export const getGeneralInfo = state => fromEmployee.getGeneralInfo(state.employee);
export const getContactInfo = state => fromEmployee.getContactInfo(state.employee);
export const getPersonalInfo = state => fromEmployee.getPersonalInfo(state.employee);
export const getCandidateStatus = state => fromEmployee.getCandidateStatus(state.employee);
export const getCandidateStatusHistory = state => fromEmployee.getCandidateStatusHistory(state.employee);
export const getEmployees = (state, ...args) => fromEmployee.getEmployees(state.employee, ...args);
export const getClientConsultants = state => fromEmployee.getClientConsultants(state.employee);
export const getInternalEmployees = (state) => fromEmployee.getInternalEmployees(state.employee);
export const getTransferredCandidates = (state) => fromEmployee.getTransferredCandidates(state.employee);
export const getChecklistedCandidates = (state, ...args) => fromEmployee.getChecklistedCandidates(state.employee, ...args);
export const getLastViewedJob = (state) => fromEmployee.getLastViewedJob(state.employee);
export const getComparedEmployees = (state, ...args) => fromEmployee.getComparedEmployees(state.employee, ...args);
export const getAppliedCandidates = (state, ...args) => fromEmployee.getAppliedCandidates(state.employee, ...args);
export const getResolvedCandidates = (state, ...args) => fromEmployee.getResolvedCandidates(state.employee, ...args);
export const getKnowYourTeamSample = state => fromEmployee.getKnowYourTeamSample(state.employee);
export const getEmployeeTeam = state => fromEmployee.getEmployeeTeam(state.employee);
export const getTalent = (state, userId) => fromEmployee.getTalent(state.employee.employees, userId);
export const getTalents = (state, vettedFilter, availabilityFilter, tags, languageFilter, profileTypeFilter, experienceFilter, currentLocationFilter, supplierFilter) =>
    fromEmployee.getTalents(state.employee, vettedFilter, availabilityFilter, tags, languageFilter, profileTypeFilter, experienceFilter, currentLocationFilter, supplierFilter);
export const getSimilarTalents = (state, filters, returnCount = 3) => fromEmployee.getSimilarTalents(state.employee, filters, returnCount);
export const getEmployeeBasicInfo = state => fromEmployee.getEmployeeBasicInfo(state.employee);
export const getEmployeeProfileCVInfo = state => fromEmployee.getEmployeeProfileCVInfo(state.employee);
export const getEmploymentActiveCompensation = state => fromEmployee.getEmploymentActiveCompensation(state.employee);
export const getEmploymentCompensationHistory = state => fromEmployee.getEmploymentCompensationHistory(state.employee);
export const getEmploymentHolidayAllowance = state => fromEmployee.getEmploymentHolidayAllowance(state.employee);
export const getEmployeeContractInformation = state => fromEmployee.getEmployeeContractInformation(state.employee);
export const getSelectedEmployee = (state, id) => fromEmployee.getSelectedEmployee(state.employee, id);
export const getCurrentEmployeeHolidaysHistory = (state, ...args) => fromEmployee.getCurrentEmployeeHolidaysHistory(state.employee, ...args);
export const getApprovedHolidays = state => fromEmployee.getApprovedHolidays(state.employee);
export const getPendingHistoryList = (state, ...args) => fromEmployee.getPendingHistoryList(state.employee, ...args);
export const getEmploymentBankDetails = state => fromEmployee.getEmploymentBankDetails(state.employee);
export const getBookedHolidaysCount = state => fromEmployee.getBookedHolidaysCount(state.employee);
export const getBookedUnpaidHolidaysCount = state => fromEmployee.getBookedUnpaidHolidaysCount(state.employee);
export const getEmployeeTasks = (state, filters) => fromEmployee.getEmployeeTasks(state.employee, filters);
export const getAllTags = state => fromEmployee.getAllTags(state.employee);
export const getAllLanguages = state => fromEmployee.getAllLanguages(state.employee);
export const getNotifications = state => fromEmployee.getNotifications(state.employee);
export const getEmployeeProjects = (state, onlyCurrent = false) => fromEmployee.getEmployeeProjects(state.employee, onlyCurrent);
export const getEmployeeProject = (state, projectId) => fromEmployee.getEmployeeProject(state.employee, projectId);
export const getEmployeeProjectDocuments = (state, projectId, page, count) => fromEmployee.getEmployeeProjectDocuments(state.employee, projectId, page, count);
export const getEmployeeProjectsWithClientInfo = (state, onlyCurrent = false) => fromEmployee.getEmployeeProjectsWithClientInfo(state.employee, onlyCurrent);
export const getEmployeeImportantDocuments = state => fromEmployee.getEmployeeImportantDocuments(state.employee);
export const getEmployeePendingHolidaysCount = state => fromEmployee.getEmployeePendingHolidaysCount(state.employee);
export const getAllEmployeesCurrentLocations = (state) => fromEmployee.getAllEmployeesCurrentLocations(state.employee);
export const getEmployeeBenefitsList = (state) => fromEmployee.getEmployeeBenefitsList(state.employee);
export const getEmployeeBenefitTemplates = (state) => fromEmployee.getEmployeeBenefitTemplates(state.employee);
export const getSkillSuggestions = state => fromEmployee.getSkillSuggestions(state.employee);
export const getIsSkillSuggestionFetching = state => fromEmployee.getIsSkillSuggestionFetching(state.employee);
export const getRelatedOpportunities = state => fromEmployee.getRelatedOpportunities(state.employee);
export const getCandidatePreferences = state => fromEmployee.getCandidatePreferences(state.employee);
export const getCandidateJobType = state => fromEmployee.getCandidateJobType(state.employee);
export const getCandidateAssignee = state => fromEmployee.getCandidateAssignee(state.employee);
export const getCandidateCriteria = state => fromEmployee.getCandidateCriteria(state.employee);
export const getCandidateJourneyInfo = state => fromEmployee.getCandidateJourneyInfo(state.employee);
export const getCandidateProgressData = state => fromEmployee.getCandidateProgressData(state.employee);
export const getCandidateInformation = state => fromEmployee.getCandidateInformation(state.employee);
export const getCandidateProfileSteps = state => fromEmployee.getCandidateProfileSteps(state.employee);
export const getIsRawCvPdfGenerated = state => fromEmployee.getIsRawCvPdfGenerated(state.employee);
export const getAdminDashboardEmployeesByCountry = state => fromAdminDashboard.getAdminDashboardEmployeesByCountry(state.adminDashboard);
export const getAdminDashboardEmployeesByUtilisation = state => fromAdminDashboard.getAdminDashboardEmployeesByUtilisation(state.adminDashboard);
export const getAdminDashboardRecentStarters = state => fromAdminDashboard.getAdminDashboardRecentStarters(state.adminDashboard);
export const getAdminDashboardRecentLeavers = state => fromAdminDashboard.getAdminDashboardRecentLeavers(state.adminDashboard);
export const getAdminDashboardUpcomingBirthdays = state => fromAdminDashboard.getAdminDashboardUpcomingBirthdays(state.adminDashboard);
export const hasUserBookedInterview = state => fromEmployee.hasUserBookedInterview(state.employee);
export const getFaceToFaceInterviewInformation = state => fromEmployee.getFaceToFaceInterviewInformation(state.employee);
export const getHottestTalents = (state) => fromEmployee.getHottestTalents(state.employee);
export const getEmployeeUploadedCv = state => fromEmployee.getEmployeeUploadedCv(state.employee);
export const getEmploymentType = state => fromEmployee.getEmploymentType(state.employee);
export const getEmployeeCvInformation = state => fromEmployee.getEmployeeCvInformation(state.employee);
export const getProfileDetails = state => fromEmployee.getProfileDetails(state.employee);
export const getEmployeeEmail = state => fromEmployee.getEmployeeEmail(state.employee);
export const getEmployeeName = state => fromEmployee.getEmployeeName(state.employee);
export const getCandidateApplicationProcessInformation = state => fromEmployee.getCandidateApplicationProcessInformation(state.employee);
export const getEmployeesTotalCount = state => fromEmployee.getEmployeesTotalCount(state.employee);
export const getEmployeeOverviewInformation = state => fromEmployee.getEmployeeOverviewInformation(state.employee);
export const getProfileCreationStepsCount = state => fromEmployee.getProfileCreationStepsCount(state.employee);

//Notification
export const isNotificationOpen = state => fromNotification.isNotificationOpen(state.notification);
export const getNotificationMessage = state => fromNotification.getNotificationMessage(state.notification);
export const getNotificationInfoList = state => fromNotification.getNotificationInfoList(state.notification);
export const getNotificationType = state => fromNotification.getNotificationType(state.notification);
export const isNotificationClickawayEnabled = state => fromNotification.isNotificationClickawayEnabled(state.notification);

//Agency
export const getAgencyRouteName = state => fromAgency.getAgencyRouteName(state.agency);
export const getAgencyBasicInfo = state => fromAgency.getAgencyBasicInfo(state.agency);
export const getHolidayAllowance = state => fromAgency.getHolidayAllowance(state.agency);
export const getPerformanceCriterias = (state, ...args) => fromAgency.getPerformanceCriterias(state.agency, ...args);
export const getImportantDocuments = (state, ...args) => fromAgency.getImportantDocuments(state.agency, ...args);
export const getAgencyTimesheets = (state, ...args) => fromAgency.getAgencyTimesheets(state.agency, ...args);
export const getCompanyLogoUrl = state => fromAgency.getCompanyLogoUrl(state.agency);
export const getAgencyMainAdmin = state => fromAgency.getAgencyMainAdmin(state.agency);
export const getAgencyPendingHolidays = (state, ...args) => fromAgency.getAgencyPendingHolidays(state.agency, ...args);
export const getAgencyApprovedHolidays = state => fromAgency.getAgencyApprovedHolidays(state.agency);
export const getAgencyHolidaysHistory = (state, ...args) => fromAgency.getAgencyHolidaysHistory(state.agency, ...args);
export const getAgencySickLeaves = state => fromAgency.getAgencySickLeaves(state.agency);
export const getBookedHolidaysCountForEmployee = (state, employeeId) => fromAgency.getBookedHolidaysCountForEmployee(state.agency, employeeId);
export const getAgencyName = state => fromAgency.getAgencyName(state.agency);
export const getAgencyNameWithoutSpecialSymbols = state => fromAgency.getAgencyNameWithoutSpecialSymbols(state.agency);
export const isHiddenSections = state => fromAgency.isHiddenSections(state.agency);
export const getAdmins = (state, page, count) => fromAgency.getAdmins(state.agency, page, count);
export const getAdminsCount = state => fromAgency.getAdminsCount(state.agency);
export const getAdminItems = (state) => fromAgency.getAdminItems(state.agency);
export const getAgencyPendingHolidaysCount = state => fromAgency.getAgencyPendingHolidaysCount(state.agency);
export const getAgencyCurrency = state => fromAgency.getAgencyCurrency(state.agency);
export const getAgencyEvrotrustEmail = state => fromAgency.getAgencyEvrotrustEmail(state.agency);
export const getAgencyCalendlyInfo = state => fromAgency.getAgencyCalendlyInfo(state.agency);
export const getAgencyImportantDocuments = state => fromAgency.getAgencyImportantDocuments(state.agency);
export const getAgencyTrainings = state => fromAgency.getAgencyTrainings(state.agency);
export const getIndividualTraining = (state, trainingId) => fromAgency.getIndividualTraining(state.agency, trainingId);
export const getCurrentTrainingFiles = (state, topicId, ...args) => fromAgency.getCurrentTrainingFiles(state.agency, topicId, ...args);
export const getAllTasks = (state, filters) => fromAgency.getAllTasks(state.agency, filters);
export const getInterviewIntroUrl = state => fromAgency.getInterviewIntroUrl(state.agency);
export const getIsAgencyEvrotrustIntegrated = state => fromAgency.getIsAgencyEvrotrustIntegrated(state.agency);
export const isClientProjectsModuleModified = state => fromAgency.isClientProjectsModuleModified(state.agency);
export const getRevenueStatistics = state => fromAgency.getRevenueStatistics(state.agency);
export const getAgencyDocuments = (state, ...args) => fromAgency.getAgencyDocuments(state.agency, ...args);
export const getAgencySettings = (state) => fromAgency.getAgencySettings(state.agency);

export const getTrainingTopicVideos = (state, topicId) => fromAgency.getTrainingTopicVideos(state, topicId);
export const getDeletedUsers = (state, ...args) => fromAgency.getDeletedUsers(state.agency, ...args);
export const getAllProjects = state => fromAgency.getAllProjects(state.agency);

//Clients
export const getClients = (state, ...args) => fromClients.getClients(state.clients, ...args);
export const getClientsCount = state => fromClients.getClientsCount(state.clients);
export const getClientsWithCount = (state, ...args) => fromClients.getClientsWithCount(state.clients, ...args);

//Client
export const getClientBasicInformation = state => fromClient.getClientBasicInformation(state.client);
export const getClientContactInformation = state => fromClient.getClientContactInformation(state.client);
export const getClientInvoicingInformation = state => fromClient.getClientInvoicingInformation(state.client);
export const getClientProjects = (state, clientId) => fromClient.getClientProjects(state.client, clientId);
export const getProjectFeedback = (state, projectId) => fromClient.getProjectFeedback(state.client, projectId);
export const getProject = (state) => fromClient.getProject(state.client);
export const getClientMenuInformation = state => fromClient.getClientMenuInformation(state.client);
export const getClientAccountManager = state => fromClient.getClientAccountManager(state.client);
export const getInvoices = (state, ...args) => fromClient.getInvoices(state.client, ...args);
export const getAllInvoices = state => fromClient.getAllInvoices(state.client);
export const getSelectedClient = (state, clientId) => fromClient.getSelectedClient(state.client, clientId);
export const getClientTasks = state => fromClient.getClientTasks(state.client);
export const getClientProjectsFeedback = state => fromClient.getClientProjectsFeedback(state.client);
export const getClientStatus = state => fromClient.getClientStatus(state.client);
export const getClientProjectDocuments = (state, page, count, sortBy, sortByDir) => fromClient.getClientProjectDocuments(state.client, page, count, sortBy, sortByDir);
export const getProjectEmployeeHistory = state => fromClient.getProjectEmployeeHistory(state.client);
export const getProjectContractEmployeesHistory = (state, page, count, sortBy, sortByDir) => fromClient.getProjectContractEmployeesHistory(state.client, page, count, sortBy, sortByDir);
export const getProjectFullTimeEmployeesHistory = (state, page, count, sortBy, sortByDir) => fromClient.getProjectFullTimeEmployeesHistory(state.client, page, count, sortBy, sortByDir);
export const getProjectAllEmployeesHistory = (state, page, count, sortBy, sortByDir) => fromClient.getProjectAllEmployeesHistory(state.client, page, count, sortBy, sortByDir);
export const getClientTotalRevenue = state => fromClient.getClientTotalRevenue(state.client);
export const getClientProjectAllTeamMembers = (state, page, count, sortBy, sortByDir) => fromClient.getClientProjectAllTeamMembers(state.client, page, count, sortBy, sortByDir);
export const getClientProjectFullTimeMembers = (state, page, count, sortBy, sortByDir) => fromClient.getClientProjectFullTimeMembers(state.client, page, count, sortBy, sortByDir);
export const getClientProjectContractMembers = (state, page, count, sortBy, sortByDir) => fromClient.getClientProjectContractMembers(state.client, page, count, sortBy, sortByDir);
export const getClientId = state => fromClient.getClientId(state.client);
export const getClientAdmins = (state, ...args) => fromClient.getClientAdmins(state.client, ...args);
export const getClientUsers = (state, ...args) => fromClient.getClientUsers(state.client, ...args);
export const getClientPastAndActiveProjectsCount = state => fromClient.getClientPastAndActiveProjectsCount(state.client);
export const getClientTeamMembersCount = state => fromClient.getClientTeamMembersCount(state.client);
export const getClientInterviewDates = state => fromClient.getClientInterviewDates(state.client);
export const getClientNotifications = state => fromClient.getClientNotifications(state.client);
export const getClientDeleteInformation = state => fromClient.getClientDeleteInformation(state.client);
export const getClientsAccountManagers = state=> fromClient.getClientsAccountManagers(state.client);

//
export const getAllContracts = (state, page, count) => fromContracts.getAllContracts(state.contracts, page, count);

//Suppliers
export const getSuppliers = (state, ...args) => fromSupplier.getSuppliers(state.supplier, ...args);
export const getSupplierBasicInformation = state => fromSupplier.getSupplierBasicInformation(state.supplier);
export const getSupplierContactInformation = state => fromSupplier.getSupplierContactInformation(state.supplier);
export const getSupplierInvoicingInformation = state => fromSupplier.getSupplierInvoicingInformation(state.supplier);
export const getSupplierMenuInformation = state => fromSupplier.getSupplierMenuInformation(state.supplier);
export const getSupplierAccountManager = state => fromSupplier.getSupplierAccountManager(state.supplier);
export const getSupplierCurrency = state => fromSupplier.getSupplierCurrency(state.supplier);
export const getSelectedSupplier = (state, supplierId) => fromSupplier.getSelectedSupplier(state.supplier, supplierId);
export const getSupplierTasks = state => fromSupplier.getSupplierTasks(state.supplier);
export const getSuppliersAccountManagers = state => fromSupplier.getSuppliersAccountManagers(state.supplier);
export const getCurrentSupplierEmployee = state => fromSupplier.getCurrentEmployee(state.supplier);
export const getSupplierInvoiceStatus = state => fromSupplier.getSupplierInvoiceStatus(state.supplier);
export const getSupplierIsDeleted = state => fromSupplier.getSupplierIsDeleted(state.supplier);

//StaticData
export const getCountries = state => fromStaticData.getCountries(state.staticData);
export const getStaticLanguages = state => fromStaticData.getStaticLanguages(state.staticData);

//Search
export const getSearchResults = state => fromSearch.getSearchResults(state.search);
export const getSearchResultsWithoutDeleted = state => fromSearch.getSearchResultsWithoutDeleted(state.search);

//ApplicationLoader
export const isAppLoaderActive = state => fromApplicationLoader.isAppLoaderActive(state.applicationLoader);

//PublicHolidays
export const getPublicHolidays = state => fromPublicHolidays.getPublicHolidays(state.publicHolidays);

//Recruiters
export const getRecruiters = (state, ...args) => fromRecruiters.getRecruiters(state.recruiters, ...args);

//Recruiter
export const getRecruiterMenuInformation = state => fromRecruiter.getRecruiterMenuInformation(state.recruiter);
export const getRecruiterAccountManager = state => fromRecruiter.getRecruiterAccountManager(state.recruiter);
export const getRecruiterBasicInformation = state => fromRecruiter.getRecruiterBasicInformation(state.recruiter);
export const getRecruiterContactInformation = state => fromRecruiter.getRecruiterContactInformation(state.recruiter);
export const getRecruitersAccountManagers = state => fromRecruiter.getRecruitersAccountManagers(state.recruiter);

//RecruiterProfiles
export const getRecruiterProfiles = (state, ...args) => fromRecruiterProfiles.getRecruiterProfiles(state.recruiterProfiles, ...args);
export const getRecruiterProfile = state => fromRecruiterProfiles.getRecruiterProfile(state.recruiterProfiles);

//Job Opportunities
export const getJobOpportunities = (state, ...args) => fromJobOpportunities.getJobOpportunities(state.jobOpportunities, ...args);
export const getJobOpportunitiesTotalCount = (state) => fromJobOpportunities.getJobOpportunitiesTotalCount(state.jobOpportunities);
export const getJobOpportunitiesContractorJobsTotalCount = (state) => fromJobOpportunities.getJobOpportunitiesContractorJobsTotalCount(state.jobOpportunities);
export const getJobOpportunitiesFullTimeJobsTotalCount = (state) => fromJobOpportunities.getJobOpportunitiesFullTimeJobsTotalCount(state.jobOpportunities);
export const getJobOpportunityDetails = state => fromJobOpportunities.getJobOpportunityDetails(state.jobOpportunities);
export const getJobOpportunitiesTechnologies = state => fromJobOpportunities.getJobOpportunitiesTechnologies(state.jobOpportunities);
export const getMatchingJobOpportunities = (state, filters) => fromJobOpportunities.getMatchingJobOpportunities(state.jobOpportunities, filters);
export const getJobsCountByJobType = (state, jobType) => fromJobOpportunities.getJobsCountByJobType(state.jobOpportunities, jobType);
export const getJobOpportunityCandidateDetails = state => fromJobOpportunities.getJobOpportunityCandidateDetails(state.jobOpportunities);
export const getMatchingAgencyConsultants = (state, filters) => fromJobOpportunities.getMatchingAgencyConsultants(state.jobOpportunities, filters);
export const getMatchingSupplierConsultants = (state, filters) => fromJobOpportunities.getMatchingSupplierConsultants(state.jobOpportunities, filters);
export const getMatchingCandidates = (state, filters) => fromJobOpportunities.getMatchingCandidates(state.jobOpportunities, filters);
export const getMatchingAllConsultantsCount = state => fromJobOpportunities.getMatchingAllConsultantsCount(state.jobOpportunities);
export const getMatchingConsultants = (state, filters) => fromJobOpportunities.getMatchingConsultants(state.jobOpportunities, filters);

//Departments
export const getDepartments = (state, ...args) => fromDepartments.getDepartments(state.departments, ...args);
export const getDepartmentDetails = state => fromDepartments.getDepartmentDetails(state.departments);

// UI/UX
export const getToggleNavigation = state => fromUi.getToggleNavigation(state.ui);
export const getToggleMobileNavigation = state => fromUi.getToggleMobileNavigation(state.ui);
export const getIsBusinessTripBannerOpen = state => fromUi.getIsBusinessTripBannerOpen(state.ui);
export const getIsOvertimeBannerOpen = state => fromUi.getIsOvertimeBannerOpen(state.ui);
export const getIsWorkspaceMigrationDialogOpen = state => fromUi.getIsWorkspaceMigrationDialogOpen(state.ui);
export const getIsIncompleteProfileDialogOpen = state => fromUi.getIsIncompleteProfileDialogOpen(state.ui);
export const getIsJourneyCompleted = state => fromUi.getIsJourneyCompleted(state.ui);
export const getIsCandidateWelcomePopupOpen = state => fromUi.getIsCandidateWelcomePopupOpen(state.ui);

// Video Interview
export const getVideoInterviewAnswers = state => fromVideoInterview.getVideoInterviewAnswers(state.videoInterview);
export const getVideoInterviewQuestionsList = state => fromVideoInterview.getVideoInterviewQuestionsList(state.videoInterview);

// Applications 
export const getCandidateApplications = (state, ...args) => fromApplications.getCandidateApplications(state.applications, ...args);
export const getCandidateApplicationsWithCount = (state, ...args) => fromApplications.getCandidateApplicationsWithCount(state.applications, ...args);
export const getCandidateApplicationsCountByStatus = (state, ...args) => fromApplications.getCandidateApplicationsCountByStatus(state.applications, ...args);
export const getCandidateApplicationDetails = state => fromApplications.getCandidateApplicationDetails(state.applications);
export const getInterviewInvitationDetails = state => fromApplications.getInterviewInvitationDetails(state.applications);
export const getInterviewInvitationsList = state => fromApplications.getInterviewInvitationsList(state.applications);
export const getAllInterviewInvitations = state => fromApplications.getAllInterviewInvitations(state.applications);
export const getParticipantInterviewInvitations = state => fromApplications.getParticipantInterviewInvitations(state.applications);
export const getParticipantPendingInterviewInvitations = state => fromApplications.getParticipantPendingInterviewInvitations(state.applications);
export const getUpcomingInterviewsList = state => fromApplications.getUpcomingInterviewsList(state.applications);
export const getNewApplicationsCount = state => fromApplications.getNewApplicationsCount(state.applications);
export const getAppliedConsultantsList = (state, ...args) => fromApplications.getAppliedConsultantsList(state.applications, ...args);
export const getAppliedCandidatesCountByStatus = state => fromApplications.getAppliedCandidatesCountByStatus(state.applications);

// Manager
export const getOwnEmployeesPendingHolidays = (state, ...args) => fromManager.getOwnEmployeesPendingHolidays(state.manager, ...args);
export const getOwnEmployeesPendingHolidayRequestsCount = (state, ...args) => fromManager.getOwnEmployeesPendingHolidayRequestsCount(state.manager);
export const getOwnEmployeesApprovedHolidays = state => fromManager.getOwnEmployeesApprovedHolidays(state.manager);
export const getOwnEmployeesHolidaysHistory = (state, ...args) => fromManager.getOwnEmployeesHolidaysHistory(state.manager, ...args);
export const getOwnEmployeesSickLeaves = state => fromManager.getOwnEmployeesSickLeaves(state.manager);
export const getOwnEmployeesDocuments = (state, ...args) => fromManager.getOwnEmployeesDocuments(state.manager, ...args);

// Historical Notes
export const getHistoricalNotes = state => fromHistoricalNotes.getHistoricalNotes(state.historicalNotes);

// Explore Talents
export const getExploreTalentsProfiles = (state) => fromExploreTalents.getExploreTalentsProfiles(state.exploreTalents);
export const getTotalTalentsCount = (state) => fromExploreTalents.getTotalTalentsCount(state.exploreTalents);

// Holidays
export const getHolidays = (state, pathname) => fromAgency.getHolidays(state, pathname);

// Sections Loaders
export const getDashboardDataFetchState = state => fromSectionsLoaders.getDashboardDataFetchState(state.sectionsLoaders);
export const getEmployeesTableDataFetchState = state => fromSectionsLoaders.getEmployeesTableDataFetchState(state.sectionsLoaders);
export const getActiveHolidaysTableDataFetchState = state => fromSectionsLoaders.getActiveHolidaysTableDataFetchState(state.sectionsLoaders);
export const getClientsTableDataFetchState = state => fromSectionsLoaders.getClientsTableDataFetchState(state.sectionsLoaders);
export const getClientsProjectsOverviewDataFetchState = state => fromSectionsLoaders.getClientsProjectsOverviewDataFetchState(state.sectionsLoaders);
export const getClientsAllProjectsDataFetchState = state => fromSectionsLoaders.getClientsAllProjectsDataFetchState(state.sectionsLoaders);
export const getJobOpportunitiesListDataFetchState = state => fromSectionsLoaders.getJobOpportunitiesListDataFetchState(state.sectionsLoaders);
export const getTasksListDataFetchState = state => fromSectionsLoaders.getTasksListDataFetchState(state.sectionsLoaders);
export const getHistoricalNotesDataFetchState = state => fromSectionsLoaders.getHistoricalNotesDataFetchState(state.sectionsLoaders);
export const getRelatedOpportunitiesDataFetchState = state => fromSectionsLoaders.getRelatedOpportunitiesDataFetchState(state.sectionsLoaders);
export const getEmployeesPersonalInformationDataFetchState = state => fromSectionsLoaders.getEmployeesPersonalInformationDataFetchState(state.sectionsLoaders);
export const getEmployeesEmploymentInformationDataFetchState = state => fromSectionsLoaders.getEmployeesEmploymentInformationDataFetchState(state.sectionsLoaders);
export const getPerformanceReviewsDataFetchState = state => fromSectionsLoaders.getPerformanceReviewsDataFetchState(state.sectionsLoaders);
export const getProfileDataFetchState = state => fromSectionsLoaders.getProfileDataFetchState(state.sectionsLoaders);
export const getTimeTrackingDataFetchState = state => fromSectionsLoaders.getTimeTrackingDataFetchState(state.sectionsLoaders);
export const getKpiReportDataFetchState = state => fromSectionsLoaders.getKpiReportDataFetchState(state.sectionsLoaders);
export const getReportsPageDataFetchState = state => fromSectionsLoaders.getReportsPageDataFetchState(state.sectionsLoaders);
export const getCandidateInterviewInvitationsDataFetchState = state => fromSectionsLoaders.getCandidateInterviewInvitationsDataFetchState(state.sectionsLoaders);
export const getBenefitsDataFetchState = state => fromSectionsLoaders.getBenefitsDataFetchState(state.sectionsLoaders);
export const getExploreTalentsDataFetchState = state => fromSectionsLoaders.getExploreTalentsDataFetchState(state.sectionsLoaders);
export const getCandidatesPreferencesDataFetchData = state => fromSectionsLoaders.getCandidatesPreferencesDataFetchData(state.sectionsLoaders);
export const getCandidateVideoInterviewDataFetchState = state => fromSectionsLoaders.getCandidateVideoInterviewDataFetchState(state.sectionsLoaders);
export const getCandidateApplicationsDataFetchState = state => fromSectionsLoaders.getCandidateApplicationsDataFetchState(state.sectionsLoaders);
export const getClientsProjectsPageDataFetchState = state => fromSectionsLoaders.getClientsProjectsPageDataFetchState(state.sectionsLoaders);
export const getContractsDataFetchState = state => fromSectionsLoaders.getContractsDataFetchState(state.sectionsLoaders);
export const getInvoicesDataFetchState = state => fromSectionsLoaders.getInvoicesDataFetchState(state.sectionsLoaders);
export const getAccountManagerDataFetchState = state => fromSectionsLoaders.getAccountManagerDataFetchState(state.sectionsLoaders);
export const getClientProjectDocumentsDataFetchState = state => fromSectionsLoaders.getClientProjectDocumentsDataFetchState(state.sectionsLoaders);
export const getJobOpportunityDetailsDataFetchState = state => fromSectionsLoaders.getJobOpportunityDetailsDataFetchState(state.sectionsLoaders);
export const getCandidateApplicationDataFetchState = state => fromSectionsLoaders.getCandidateApplicationDataFetchState(state.sectionsLoaders);
export const getProjectDetailsFetchState = state => fromSectionsLoaders.getProjectDetailsFetchState(state.sectionsLoaders);
export const getCandidateOverviewPageDataFetchState = state => fromSectionsLoaders.getCandidateOverviewPageDataFetchState(state.sectionsLoaders);
export const getClientProjectsWithEmployeesDataFetchState = state => fromSectionsLoaders.getClientProjectsWithEmployeesDataFetchState(state.sectionsLoaders);
export const getClientOverviewFetchState = state => fromSectionsLoaders.getClientOverviewFetchState(state.sectionsLoaders);
export const getClientCompanyInformationFetchState = state => fromSectionsLoaders.getClientCompanyInformationFetchState(state.sectionsLoaders);
export const getEmployeeOverviewFetchState = state => fromSectionsLoaders.getEmployeeOverviewFetchState(state.sectionsLoaders);
export const getLoginLoading = state => fromSectionsLoaders.getLoginLoading(state.sectionsLoaders);
export const getSSOLoginLoading = state => fromSectionsLoaders.getSSOLoginLoading(state.sectionsLoaders);
export const getEmployeeProfileCVFetchState = state => fromSectionsLoaders.getEmployeeProfileCVFetchState(state.sectionsLoaders);
export const getRawCvProgressDataFetchState = state => fromSectionsLoaders.getRawCvProgressDataFetchState(state.sectionsLoaders);
export const getClientInterviewsDataFetchState = state => fromSectionsLoaders.getClientInterviewsDataFetchState(state.sectionsLoaders);
export const getClientJobDashboardDataFetchState = state => fromSectionsLoaders.getClientJobDashboardDataFetchState(state.sectionsLoaders);
export const getJobOpportunityPageDataFetchState = state => fromSectionsLoaders.getJobOpportunityPageDataFetchState(state.sectionsLoaders);
export const getJobMatchingConsultantsPageFetchState = state => fromSectionsLoaders.getJobMatchingConsultantsPageFetchState(state.sectionsLoaders);
export const getBusinessTripsListDataFetchState = state => fromSectionsLoaders.getBusinessTripsListDataFetchState(state.sectionsLoaders);
export const getDocumentsTableDataFetchState = state => fromSectionsLoaders.getDocumentsTableDataFetchState(state.sectionsLoaders);
export const getChecklistedCandidatesDataFetchState = state => fromSectionsLoaders.getChecklistedCandidatesDataFetchState(state.sectionsLoaders);
export const getSearchDataFetchState = state => fromSectionsLoaders.getSearchDataFetchState(state.sectionsLoaders);
export const getApplicationProcessInformationFetchState = state => fromSectionsLoaders.getApplicationProcessInformationFetchState(state.sectionsLoaders);
export const getCandidateProfilePageDataFetchState = state => fromSectionsLoaders.getCandidateProfilePageDataFetchState(state.sectionsLoaders);
export const getDialogActionButtonLoading = state => fromSectionsLoaders.getDialogActionButtonLoading(state.sectionsLoaders);
export const getActionButtonLoading = state => fromSectionsLoaders.getActionButtonLoading(state.sectionsLoaders);
export const getAiMatchedCandidatesScoresFetchState = state => fromSectionsLoaders.getAiMatchedCandidatesScoresFetchState(state.sectionsLoaders);
export const getCvPageUpdateState = state => fromSectionsLoaders.getCvPageUpdateState(state.sectionsLoaders);
export const getSupplierEmployeeOverviewPageLoadingState = state => fromSectionsLoaders.getSupplierEmployeeOverviewPageLoadingState(state.sectionsLoaders);


// Main Menu
export const getMenuInformation = state => fromMenu.getMenuInformation(state.menu);
export const getIsInitialJourneyCompleted = state => fromMenu.getIsInitialJourneyCompleted(state.menu);
export const getInitialJourneyCompletedStepsIds = state => fromMenu.getInitialJourneyCompletedStepsIds(state.menu);
export const getCompletedByAdminStepsIds = state => fromMenu.getCompletedByAdminStepsIds(state.menu);
export const getCurrentJourneyStepId = state => fromMenu.getCurrentJourneyStepId(state.menu);

// Coaching Opportunities
export const getCoachingOpportunities = state => fromCoachingOpportunities.getCoachingOpportunities(state.coachingOpportunities);
export const getCoachingOpportunity = state => fromCoachingOpportunities.getCoachingOpportunity(state.coachingOpportunities);
export const getCoachingOpportunityCalendlyUrl = state => fromCoachingOpportunities.getCoachingOpportunityCalendlyUrl(state.coachingOpportunities);
export const getCoachingOpportunitiesSessions = state => fromCoachingOpportunities.getCoachingOpportunitiesSessions(state.coachingOpportunities);
export const getCoachingOpportunitiesUpcomingSessions = state => fromCoachingOpportunities.getCoachingOpportunitiesUpcomingSessions(state.coachingOpportunities);
export const getCoachingOpportunitiesPastSessions = state => fromCoachingOpportunities.getCoachingOpportunitiesPastSessions(state.coachingOpportunities);
export const getCoachingOpportunitySessions = state => fromCoachingOpportunities.getCoachingOpportunitySessions(state.coachingOpportunities);

//Business Trips
export const getBusinessTrips = (state, ...args) => fromBusinessTrips.getBusinessTrips(state.businessTrips, ...args);

// Reports
export const getReportsData = (state) => fromReports.getReportsData(state.reports);

// Language
export const getLanguage = (state) => fromLanguage.getLanguage(state.language); 
