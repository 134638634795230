import { CANDIDATE_REJECTION_REASONS_KEYS, CANDIDATE_STATUSES } from 'constants/candidateConstants';
import { convertStringToLanguageKey } from 'utils/languageUtils';

export const getWelcomeInformation = (intl, personalInfo, preferences) => {
    switch (personalInfo?.status) {
        case CANDIDATE_STATUSES.VETTED:
            return { text: intl.formatMessage({ id: "candidate-status-vetted-text" }) }
        case CANDIDATE_STATUSES.REJECTED:
            let showButton;
            let buttonText;
            let text;

            switch (personalInfo?.rejectionReason) {
                case CANDIDATE_REJECTION_REASONS_KEYS.UNSUITABLE_LOCATION:
                    showButton = true;
                    buttonText = intl.formatMessage({ id: "update" });
                    text = `${intl.formatMessage({ id: "candidate-status-unsuitable-location-text-one" })}${preferences?.countryOfTax ? ` (${intl.formatMessage({ id: convertStringToLanguageKey(preferences?.countryOfTax) })})` : ''}. ${intl.formatMessage({ id: "candidate-status-unsuitable-location-text-two" })}.`;
                    break;
                case CANDIDATE_REJECTION_REASONS_KEYS.UNREASONABLE_REMUNERATION:
                    showButton = true;
                    buttonText = intl.formatMessage({ id: "update" });
                    text = intl.formatMessage({ id: "candidate-status-unreasonable-remuneration" });
                    break;
                case CANDIDATE_REJECTION_REASONS_KEYS.LACKING_SKILLS:
                    showButton = true;
                    buttonText =  intl.formatMessage({ id: "request" });
                    text = intl.formatMessage({ id: "lacking-skills" });
                    break;
                default:
                    break;
            }

            return {
                showButton,
                buttonText,
                text
            }
        case CANDIDATE_STATUSES.INCOMPLETE_PROFILE:
            return { text: '' }
        default:
            return { text: intl.formatMessage({ id: "candidate-status-being-reviewed" }) }
    }
};
