import { invalidSessionLogout } from 'actions/authActions';
import { showNotification } from 'actions/notificationActions';
import { INVALID_SESSION_MESSAGES, UNAUTHORIZED_ACCESS_MESSAGES } from 'constants/authConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

export const authorizedShowNotification = (error) => (errorMessage, errorType) => {
    if (INVALID_SESSION_MESSAGES.includes(error.message)) {
        return invalidSessionLogout();
    } else if (UNAUTHORIZED_ACCESS_MESSAGES.includes(error.message)) {
        return showNotification(error.message, NOTIFICATION_TYPES.ERROR);
    }

    return showNotification(errorMessage, errorType);
}
