import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';

import { sendJobApplicationStatusRequestEmail } from 'actions/jobApplicationActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as AskRecruiterIcon } from 'assets/ask-recruiter-icon.svg';

import { getRemainingTime } from './AskRecruiterBannerUtils';

import './AskRecruiterBanner.scss';

let interval;

const AskRecruiterBanner = ({
    agencyId,
    applicationId,
    applicationStatuRequestDate,
    sendJobApplicationStatusRequestEmail,
}) => {
    const intl = useIntl();
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(applicationStatuRequestDate));

    useEffect(() => {
        if (remainingTime) {
            interval = setInterval(() => setRemainingTime(getRemainingTime(applicationStatuRequestDate)), 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [remainingTime]);

    useEffect(() => {
        setRemainingTime(getRemainingTime(applicationStatuRequestDate));
    }, [applicationStatuRequestDate]);

    const handleAskRecruiterClick = () => {
        sendJobApplicationStatusRequestEmail(agencyId, applicationId);
    };

    return (
        <div className="ask-recruiter-banner-container">
            <div className="ask-recruiter-banner-content">
                <h4 className="banner-title">{intl.formatMessage({ id: "what-is-the-status" })}</h4>
                <p className="banner-body-text">
                    {intl.formatMessage({ id: "what-is-the-status-content" })}
                </p>
                <div>
                    <PrimaryButton
                        className="ask-the-recruiter-button"
                        text={intl.formatMessage({ id: "ask-the-recruiter" })}
                        handleClick={handleAskRecruiterClick}
                        disabled={Boolean(remainingTime)}
                    />
                    {remainingTime && <p className="remaining-time">{intl.formatMessage({ id: "available-in" })} {remainingTime}</p>}
                </div>
            </div>
            <div className="ask-recruiter-banner-icon-wrapper">
                <AskRecruiterIcon />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    sendJobApplicationStatusRequestEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AskRecruiterBanner);
