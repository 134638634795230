import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { getCandidateApplicationProcessInformation, getPersonalInfo, getCandidatePreferences } from 'reducers';

import { Tooltip } from '@material-ui/core';

import { TOOLTIP_TEXT, APPLICATION_PROCESS_STEPS } from './ApplicationProcessBannerConstants';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/ionic-ios-arrow-round-forward.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';

import { getWelcomeInformation } from './ApplicationProcessBannerUtils';
import { convertStringToLanguageKey } from 'utils/languageUtils';
import { getStateAgencyName } from 'utils/helpers';

import './ApplicationProcessBanner.scss';

const ApplicationProcessBanner = ({
    candidateApplicationProcessInformation,
    personalInfo,
    preferences,
    showWelcomeBack = false,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const { text, showButton, buttonText } = getWelcomeInformation(intl, personalInfo, preferences);

    const handleUpdateClick = () => history.push(`/${getStateAgencyName()}/profile`);

    return (
        <div className="application-process-banner-wrapper">
            {(showWelcomeBack && text) &&
                <div className="welcome-back-wrapper">
                    <h2 className="welcome-back-title">
                        {intl.formatMessage({ id: "welcome-back" })}!
                    </h2>
                    <p className="welcome-back-text">
                        {text}
                    </p>
                    {showButton && <PrimaryButton className="welcome-back-update-btn" text={buttonText} handleClick={handleUpdateClick} />}
                </div>
            }

            <h2 className="application-process-banner-title">
                {intl.formatMessage({ id: "typical-application-process" })}
                <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={intl.formatMessage({ id: convertStringToLanguageKey(TOOLTIP_TEXT) })} placement="top-end">
                    <InfoIcon className="info-icon" />
                </Tooltip>
            </h2>
            <div className="application-process-banner-steps-container">
                {APPLICATION_PROCESS_STEPS.map((step, index) => {
                    const previousStep = APPLICATION_PROCESS_STEPS.find(x => x._id === (step._id - 1));
                    const isPreviousStepFinished = candidateApplicationProcessInformation[previousStep?.propertyName];
                    const isStepFinished = candidateApplicationProcessInformation[step.propertyName];

                    return (
                        <Fragment key={step._id}>
                            <div className={`step-container${isStepFinished ? ' current-done' : ''}${isPreviousStepFinished ? ' previous-done' : ''}`}>
                                <div className={`step-box-wrapper`}>
                                    <div className={`step-box`}>
                                        <div className="step-box-content">
                                            {isStepFinished
                                                ? <CheckmarkIcon className="checkmark-icon" />
                                                : <span>{step._id}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="step-text-wrapper">
                                    <p className="step-text">{intl.formatMessage({ id: convertStringToLanguageKey(step.text) })}</p>
                                </div>
                            </div>
                            {index !== APPLICATION_PROCESS_STEPS.length - 1 &&
                                <ArrowIcon className={`arrow-icon${isStepFinished ? ' done' : ''}`} />
                            }
                        </Fragment>
                    )
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    candidateApplicationProcessInformation: getCandidateApplicationProcessInformation(state),
    personalInfo: getPersonalInfo(state),
    preferences: getCandidatePreferences(state),
});

export default connect(mapStateToProps)(ApplicationProcessBanner);
