import React from 'react';
import { useIntl } from 'react-intl';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { ReactComponent as LightIcon } from 'assets/light-icon.svg';
import { ReactComponent as LampIcon } from 'assets/lamp-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as MicIcon } from 'assets/mic-icon.svg';
import { ReactComponent as BackgroundIcon } from 'assets/background-icon.svg';

import './HowToRecordBanner.scss';

const HowToRecordBanner = () => {
    const intl = useIntl();

    return (
        <PaperElement classes={["how-to-record-banner", "m", "lower-t-margin", "no-border-radius"]}>
            <PaperContent>
                <Accordion
                    className="accordion-wrapper"
                    elevation={0}
                >
                    <AccordionSummary
                        className="accordion-summary"
                        expandIcon={<KeyboardArrowLeftIcon />}
                    >
                        <div className="col-1of2">
                            <div className="light-icon-wrapper">
                                <LightIcon />
                            </div>
                            <h1 className="summary-text">{intl.formatMessage({ id: "how-to-do-a-great-intro" })}</h1>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails
                        className="details-wrapper"
                    >
                        <div className="column-items">
                            <div className="info-item">
                                <div className="icon-wrapper">
                                    <LampIcon />
                                </div>
                                <p>{intl.formatMessage({ id: "record-in-well-lit-room" })}</p>
                            </div>
                            <div className="info-item">
                                <div className="icon-wrapper">
                                    <EyeIcon />
                                </div>
                                <p>{intl.formatMessage({ id: "make-eye-contact-with-the-camera" })}</p>
                            </div>
                        </div>
                        <div className="column-items">
                            <div className="info-item">
                                <div className="icon-wrapper">
                                    <MicIcon />
                                </div>
                                <p>{intl.formatMessage({ id: "speak-slowly-and-clearly" })}</p>
                            </div>
                            <div className="info-item">
                                <div className="icon-wrapper">
                                    <BackgroundIcon />
                                </div>
                                <p>{intl.formatMessage({ id: "keep-your-background-simple" })}</p>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </PaperContent>
        </PaperElement>
    );
};

export default HowToRecordBanner;
