import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';

import { applyForJob } from 'actions/jobApplicationActions';
import { getCandidatePreferences, getIsInitialJourneyCompleted } from 'reducers';

import Divider from '@material-ui/core/Divider';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';
import UnsupportedLocationDialog from 'components/Shared/UnsupportedLocationDialog';
import UnfinishedProfilePopup from '../JobDetails/UnfinishedProfilePopup';

import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';

import { ReactComponent as CompanyIcon } from 'assets/icon-company.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-location.svg';
import { ReactComponent as DateAddedIcon } from 'assets/icon-date-added.svg';
// import { ReactComponent as JobSecurityIcon } from 'assets/icon-job-security.svg';
// import { ReactComponent as JobFlexibilityIcon } from 'assets/icon-job-flexibility.svg';
// import { ReactComponent as RemoteWorkIcon } from 'assets/icon-remote-work.svg';
// import { ReactComponent as SupportiveCommunityIcon } from 'assets/icon-community.svg';
// import { ReactComponent as EquipmentIcon } from 'assets/icon-equipment.svg';
// import { ReactComponent as WellBeingIcon } from 'assets/icon-well-being.svg';
// import { ReactComponent as GrowthIcon } from 'assets/icon-growth.svg';

import { getStateAgencyName } from 'utils/helpers';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { CANDIDATE_STATUSES } from 'constants/candidateConstants';

import './Job.scss'

const Job = ({
    jobOpportunity,
    applyForJob,
    agencyId,
    setIsConfirmDialogOpen,
    applyBtnRef,
    candidateStatus,
    candidatePreferences,
    isInitialJourneyCompleted,
    handleBackToFinishProcess,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [isUnsupportedLocationDialogOpen, setIsUnsupportedLocationDialogOpen] = useState(false);
    const [isUnfinishedProfileDialogOpen, setIsUnfinishedProfileDialogOpen] = useState(false);

    const handleCloseUnsupportedLocationDialog = () => {
        setIsUnsupportedLocationDialogOpen(false);
    }
    const handleApplyAfterSeeingDialog = () => {
        handleApplyForJobAction();
    }

    const handleApplyForJobAction = () => {
        const applicationDetails = {};

        setIsConfirmDialogOpen(false);
        applyForJob(agencyId, jobOpportunity._id, applicationDetails).then((applicationId) => {
            history.push(`/${getStateAgencyName()}/my-applications/${applicationId}`);
        });
    };

    const preferencesMatchJobLocations = jobOpportunity.geographicLocations?.length ? jobOpportunity.geographicLocations.includes(candidatePreferences.countryOfTax) : true

    const handleApplyBtnClick = () => {
        if (preferencesMatchJobLocations && isInitialJourneyCompleted) {
            handleApplyForJobAction();
        } else if (!isInitialJourneyCompleted) {
            setIsUnfinishedProfileDialogOpen(true);
        } else {
            setIsUnsupportedLocationDialogOpen(true);
        }
    };

    const textComponent = () => (
        <p className="unsupported-location-text">
            {intl.formatMessage({ id: "unsupported-location-text-one" })} <span>({intl.formatMessage({ id: convertStringToLanguageKey(candidatePreferences.countryOfTax) })})</span>.
            {intl.formatMessage({ id: "unsupported-location-text-two" })}.
        </p>
    );
    
    const dialogActionsComponent = () => (
        <>
            <DialogDiscardButton onClick={handleCloseUnsupportedLocationDialog}>{intl.formatMessage({ id: "discard" })}</DialogDiscardButton>
            <DialogSaveButton onClick={handleApplyAfterSeeingDialog}>{intl.formatMessage({ id: "apply" })}</DialogSaveButton>
        </>
    );

    return (
        <div className="job-wrapper">
            <div className={`job-container${isMobile ? ' mobile' : ''}`}>
                <div className="job-inner">
                    <JobTypeLabel jobType={jobOpportunity.jobType} />
                    <div className="job-header-wrapper">
                        <div className="header-info">
                            <div className="job-title">
                                <h4>{jobOpportunity.position}</h4>
                                {jobOpportunity.isLastViewedJob && <span className="label seen">{intl.formatMessage({ id: "seen" })}</span>}
                                {jobOpportunity.application?.hasApplied && <span className="label applied">{intl.formatMessage({ id: "applied" })}</span>}
                            </div>
                            <div className="job-company-date-wrapper">
                                <div className="job-company">
                                    <CompanyIcon className="icon" />
                                    <p>{jobOpportunity.client.name}</p>
                                </div>
                                <div className="job-date">
                                    <DateAddedIcon className="icon" />
                                    <p>{moment(jobOpportunity.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                                </div>
                            </div>
                            <div className="job-location">
                                <LocationIcon className="icon" />
                                <p>{`${jobOpportunity.geographicLocations?.length ? `${intl.formatMessage({ id: "remote" })} (${jobOpportunity.geographicLocations.map(x => intl.formatMessage({ id: convertStringToLanguageKey(x) })).join(", ")})` : intl.formatMessage({ id: "remote" })}`}</p>
                            </div>
                        </div>
                    </div>
                    <Divider className="divider" variant="middle" />
                    <div className="job-body">
                        {
                            jobOpportunity.aboutTheClient &&
                            <div className="job-description">
                                <h6 className="description-title">{intl.formatMessage({ id: "about-the-client" })}</h6>
                                <pre className="text-description">{jobOpportunity.aboutTheClient}</pre>
                            </div>
                        }
                        {
                            jobOpportunity.jobSummary &&
                            <div className="job-description">
                                <h6 className="description-title">{intl.formatMessage({ id: "about-the-job" })}</h6>
                                <pre className="text-description">{jobOpportunity.jobSummary}</pre>
                            </div>
                        }
                        {
                            jobOpportunity.aboutCandidate &&
                            <div className="job-description">
                                <h6 className="description-title">{intl.formatMessage({ id: "responsibilities" })}</h6>
                                <pre className="text-description">{jobOpportunity.aboutCandidate}</pre>
                            </div>
                        }
                        {
                            jobOpportunity.mandatorySkills?.length > 0 &&
                            <div className="mandatory-skills">
                                <h6 className="description-title">{intl.formatMessage({ id: "mandatory-skills" })}</h6>
                                <div className="skills-list">
                                    {jobOpportunity.mandatorySkills?.map((skill, index) => <div key={`mandatory-${index}-${skill.name}`} className="skill"><p>{skill.name}</p></div>)}
                                </div>
                            </div>
                        }
                        {
                            jobOpportunity.niceToHaveSkills?.length > 0 &&
                            <div className="nice-to-have-skills">
                                <h6 className="description-title">{intl.formatMessage({ id: "nice-to-have-skills" })}</h6>
                                <div className="skills-list">
                                    {jobOpportunity.niceToHaveSkills?.map((skill, index) => <div key={`nice-to-have-${index}-${skill.name}`} className="skill"><p>{skill.name}</p></div>)}
                                </div>
                            </div>
                        }
                        {
                            jobOpportunity.requirements &&
                            <div className="job-description">
                                <h6 className="description-title">{intl.formatMessage({ id: "requirements" })}</h6>
                                <pre className="text-description">{jobOpportunity.requirements}</pre>
                            </div>
                        }
                        {
                            jobOpportunity.benefits &&
                            <div className="job-description">
                                <h6 className="description-title">{intl.formatMessage({ id: "additional-benefits" })}</h6>
                                <pre className="text-description">{jobOpportunity.benefits}</pre>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                (!jobOpportunity.application.hasApplied && (candidateStatus !== CANDIDATE_STATUSES.REJECTED && candidateStatus !== CANDIDATE_STATUSES.INCOMPLETE_PROFILE)) &&
                <PrimaryButton text={intl.formatMessage({ id: "apply" })} data-testid="apply-to-job-btn" ref={applyBtnRef} className={"apply-button"} handleClick={handleApplyBtnClick} />
            }
            {/* <div className="benefits">
                <div className="benefit">
                    <JobSecurityIcon className="benefit-icon" />
                    <h6 className="benefit-title">JOB SECURITY</h6>
                    <p className="benefit-text">Choose when and where you work from while doing the things you love. Your contribution is not measured based on how many hours you’ve spent at your desk.</p>
                </div>
                <div className="benefit">
                    <SupportiveCommunityIcon className="benefit-icon" />
                    <h6 className="benefit-title">SUPPORTIVE COMMUNITY</h6>
                    <p className="benefit-text">By joining Motion Software, you instantly become part of an ever-growing remote community of specialists who look after each other and grow together.</p>
                </div>
                <div className="benefit bottom-benefit">
                    <JobFlexibilityIcon className="benefit-icon" />
                    <h6 className="benefit-title">FLEXIBILITY</h6>
                    <p className="benefit-text">Find your own creative space. Whether that’s home office, a co-working space or Motion Software’s offices, we will support you.</p>
                </div>
                <div className="benefit bottom-benefit">
                    <RemoteWorkIcon className="benefit-icon" />
                    <h6 className="benefit-title">REMOTE WORK</h6>
                    <p className="benefit-text">Choose when and where you work from while doing the things you love. Your contribution is not measured based on how many hours you’ve spent at your desk.</p>
                </div>
            </div>
            <div className={`footer${isMobile ? ' mobile' : ''}`}>
                <div className="inner">
                    <div className="key-points">
                        <GrowthIcon />
                        <h6>GROWTH</h6>
                    </div>
                    <div className="key-points">
                        <WellBeingIcon />
                        <h6>WELL-BEING</h6>
                    </div>
                    <div className="key-points">
                        <EquipmentIcon />
                        <h6>EQUIPMENT</h6>
                    </div>
                </div>
            </div> */}
            {
                isUnsupportedLocationDialogOpen &&
                    <UnsupportedLocationDialog
                        isOpen={isUnsupportedLocationDialogOpen}
                        handleCloseDialog={handleCloseUnsupportedLocationDialog}
                        locationTextComponent={textComponent}
                        dialogActionsComponent={dialogActionsComponent}
                    />
            }
            {
                isUnfinishedProfileDialogOpen &&
                    <UnfinishedProfilePopup
                        handleClick={handleBackToFinishProcess}
                    />
            }
        </div >
    );
}

const maStateToProps = (state) => ({
    candidatePreferences: getCandidatePreferences(state),
    isInitialJourneyCompleted: getIsInitialJourneyCompleted(state),
});

const mapDispatchToProps = {
    applyForJob,
};

export default connect(maStateToProps, mapDispatchToProps)(Job);
