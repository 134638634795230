import { convertStringToLanguageKey } from "utils/languageUtils";
import { JOB_TYPES } from "../YourProfileConstants";

export const getSelectionCardSubTitle = (intl, candidateJobType) => {
    switch (candidateJobType) {
        case JOB_TYPES.DEVELOPER:
            return `${intl.formatMessage({ id: 'years-of-experience-info-text' })} Software Engineer?`;
        case JOB_TYPES.OTHER:
            return `${intl.formatMessage({ id: 'years-of-experience-info-text-in-field' })}`
        default:
            return `${intl.formatMessage({ id: 'years-of-experience-info-text' })} ${intl.formatMessage({ id: convertStringToLanguageKey(candidateJobType)}).toLowerCase()}?`;
    }
};
