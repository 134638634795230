import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SendProfilesDialog from 'components/Shared/SendProfilesDialog';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import Banner from 'components/Shared/Banner';

import { ReactComponent as BannerIcon } from 'assets/banner-user-check.svg';

import { getStateAgencyName } from 'utils/helpers';

import { DEFAULT_FILTER_QUERY } from 'constants/matchingConsultantsConstants';

import './JobOptionsBanner.scss';
import { BULGARIAN_LABEL } from 'constants/languages';

const JobOptionsBanner = ({
    jobId,
    job,
    isAdmin,
    agencyName,
    displayName,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const handleFindMatchClick = () => {
        history.push(`/${getStateAgencyName()}/jobs/${jobId}/matching-consultants${DEFAULT_FILTER_QUERY}`);
    };

    const [isSendProfilesDialogOpen, setIsSendProfilesDialogOpen] = useState(false);

    const handleOpenSendProfilesDialog = () => setIsSendProfilesDialogOpen(true);

    const handleCloseSendProfilesDialog = () => setIsSendProfilesDialogOpen(false);

    const getBannerText = () => {
        if (isAdmin) {
            if (intl.locale === BULGARIAN_LABEL) {
                return "Като щракнете върху \"Намиране на съвпадение\", ще видите всички профили на WIA (служители, партньори или кандидати), които отговарят на заявката на клиента. Използването на другата опция ви дава възможност да изберете ръчно един или повече профили и да ги изпратите на клиента."
            } else {
                return "By clicking \"Find a Match\" you will see all profiles on WIA (employees, partners or candidates) who match the client request. Using the other option gives you the ability to hand pick one or more profiles and send them to the client."
            }
        } else {
            if (intl.locale === BULGARIAN_LABEL) {
                return `Използването на бутона по-долу ви позволява да избирате ръчно профили на вашите налични служители и да ги изпращате за преглед на ${agencyName}.`
            }
            return `Using the button below enables you to handpick profiles of your available employees and send them for review to ${agencyName}.`
        }
    };

    return (
        <Fragment>
            <Banner
                title={intl.formatMessage({ id: "find-a-match-candidate" })}
                content={getBannerText()}
                icon={<BannerIcon />}
                primaryButtonComponent={isAdmin ? <SecondaryButton
                    text={intl.formatMessage({ id: "find-a-match-candidate" })}
                    handleClick={handleFindMatchClick}
                /> : null}
                secondaryButtonComponent={<PrimaryButton
                    className="send-profiles-button"
                    text={intl.formatMessage({ id: "send-profiles" })}
                    handleClick={handleOpenSendProfilesDialog}
                />}
            />

            {isSendProfilesDialogOpen &&
                <SendProfilesDialog
                    job={job}
                    isSelectDialogOpen={isSendProfilesDialogOpen}
                    handleCloseDialog={handleCloseSendProfilesDialog}
                />
            }
        </Fragment>
    );
};

export default JobOptionsBanner;
