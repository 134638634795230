export const JOB_TYPES = {
    DEVELOPER: 'Developer',
    PROJECT_MANAGER: 'Project Manager',
    QA_ENGINEER: 'QA Engineer',
    DEVOPS_ENGINEER: 'DevOps Engineer',
    DESIGNER: 'Designer',
    TEACHER: 'Teacher',
    CUSTOMER_SUPPORT_AND_ADMINISTRATION_SPECIALIST: 'Customer Support & Administration Specialist',
    OPERATIONS_OR_TECH_SUPPORT_SPECIALIST: 'Operations or Tech Support Specialist',
    MARKETING_SPECIALIST: 'Marketing Specialist',
    BUSINESS_DEVELOPMENT_SPECIALIST: 'Business Development Specialist',
    HR_SPECIALIST: 'HR Specialist',
    FINANCE_SPECIALIST: 'Finance Specialist',
    REGULATORY_SPECIALIST: 'Regulatory Specialist',
    OTHER: 'Other',
}

export const CANDIDATE_JOB_TYPE_OPTIONS = [
    {
        title: 'Teacher',
        value: JOB_TYPES.TEACHER,
    },
    {
        title: 'Customer Support & Administration Specialist',
        value: JOB_TYPES.CUSTOMER_SUPPORT_AND_ADMINISTRATION_SPECIALIST,
    },
    {
        title: 'Operations or Tech Support Specialist',
        value: JOB_TYPES.OPERATIONS_OR_TECH_SUPPORT_SPECIALIST,
    },
    {
        title: 'Marketing Specialist',
        value: JOB_TYPES.MARKETING_SPECIALIST,
    },
    {
        title: 'Business Development Specialist',
        value: JOB_TYPES.BUSINESS_DEVELOPMENT_SPECIALIST,
    },
    {
        title: 'HR Specialist',
        value: JOB_TYPES.HR_SPECIALIST,
    },
    {
        title: 'Finance Specialist',
        value: JOB_TYPES.FINANCE_SPECIALIST,
    },
    {
        title: 'Regulatory Specialist',
        value: JOB_TYPES.REGULATORY_SPECIALIST,
    },
    {
        title: 'Other',
        value: JOB_TYPES.OTHER,
    },
];

export const CANDIDATE_EXPERIENCE_OPTIONS = [
    { value: '<1 year', title: '<1 year' },
    { value: '1-2 years', title: '1-2 years' },
    { value: '2-3 years', title: '2-3 years' },
    { value: '3-5 years', title: '3-5 years' },
    { value: '5+ years', title: '5+ years' },
];

export const LESS_THAN_ONE_YEAR_EXPERIENCE = CANDIDATE_EXPERIENCE_OPTIONS[0].value;
export const ONE_TO_TWO_YEARS_EXPERIENCE = CANDIDATE_EXPERIENCE_OPTIONS[1].value;
