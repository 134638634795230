import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import moment from 'moment';

import { getCandidateApplicationDetails, getJobOpportunityDetails, isAdmin, isClient } from 'reducers';

import { updateInterviewStatus } from 'actions/interviewInvitationActions';
import { updateApplicationStatus } from 'actions/jobApplicationActions';
import { sendProfileToClient } from 'actions/jobOpportunityActions';

import RejectApplicationDialog from 'components/Shared/RejectApplicationDialog';
import RejectInterviewAndApplicationDialog from 'components/Shared/RejectInterviewAndApplicationDialog';
import ApproveApplicationDialog from 'components/Shared/ApproveApplicationDialog';
import AddCommentsDialog from 'components/Shared/SendProfilesDialog/AddCommentsDialog';
import MoveToEmployeesDialog from 'components/Shared/MoveToEmployeesDialog';
import ConfirmDialog from 'components/Shared/ConfirmDialog';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { STEPS_OF_REJECTION } from 'constants/candidateConstants';
import { JOB_OPPORTUNITIES_TYPES, JOB_PROFILE_FORWARD_TYPES } from 'constants/jobOpportunitiesConstants';
import { MOVE_TO_EMPLOYEES_HELPER_TEXT } from './ApplicationInterviewActionsConstants';
import { ROLES } from 'constants/userConstants';

import './ApplicationInterviewActions.scss';

const ApplicationInterviewActions = ({
    agencyId,
    applicationId,
    agencyName,
    interviewInvitation,
    candidateApplicationDetails,
    updateInterviewStatus,
    updateApplicationStatus,
    sendProfileToClient,
    jobDetails,
    isClientMode,
    isAdminMode,
    clientId,
}) => {
    const intl = useIntl();

    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    const [isRejectInterviewAndApplicationDialogOpen, setIsRejectInterviewAndApplicationDialogOpen] = useState(false);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [isAddCommentsDialogOpen, setIsAddCommentsDialogOpen] = useState(false);
    const [isMoveToAgencyDialogOpen, setIsMoveToAgencyDialogOpen] = useState(false);
    const [isConfirmCloseMoveToAgencyDialogOpen, setIsConfirmCloseMoveToAgencyDialogOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);

    const hideAll = candidateApplicationDetails.status === APPLICATION_STATUSES.APPROVED || candidateApplicationDetails.status === APPLICATION_STATUSES.REJECTED;
    const isAcceptedAnotherOffer = candidateApplicationDetails.status === APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER;
    const hasInterview = interviewInvitation._id;
    const hasInterviewPassed = moment().isAfter(interviewInvitation.interviewDate?.date);
    const hasPreviousInterview = interviewInvitation.hasPreviousInterview;
    const isPendingAdminReview = candidateApplicationDetails.status === APPLICATION_STATUSES.PENDING_ADMIN_REVIEW;

    //Reject dialogs logic
    const handleRejectClick = () => {
        (!hasInterview || hasInterviewPassed)
            ? setIsRejectDialogOpen(true)
            : setIsRejectInterviewAndApplicationDialogOpen(true);
    };

    const handleRejectSubmit = (stepOfRejection, feedback) => {
        const statusData = { status: APPLICATION_STATUSES.REJECTED, stepOfRejection, feedback }
        updateApplicationStatus(agencyId, applicationId, statusData);
    };

    const handleRejectInterview = (feedback) => {
        updateInterviewStatus(agencyId, interviewInvitation._id, { feedback, status: APPLICATION_STATUSES.REJECTED });
    };

    //Approve dialog Logic
    const handleCloseApproveDialog = () => setIsApproveDialogOpen(false);

    const handleOpenApproveDialog = () => {
        setIsApproveDialogOpen(true);
    };

    const handleForwardToClient = async (profiles, notifyClient) => {
        //The dialog handleSubmit returns array, but in this case it will be only 1 profile
        const profile = {
            _id: profiles[0]._id,
            comment: profiles[0].comment,
            jobApplicationId: candidateApplicationDetails._id
        };

        await sendProfileToClient(agencyId, candidateApplicationDetails.jobId, profile, notifyClient);
        handleCloseAddCommentsDialog();
    };

    const handleCloseAddCommentsDialog = () => {
        setIsAddCommentsDialogOpen(false);
    };

    const handleOpenAddCommentsDialog = () => {
        setIsAddCommentsDialogOpen(true);
    };

    const handleHireCandidate = async (agencyId, applicationId, statusData) => {
        const applicantRole = candidateApplicationDetails.role;

        await updateApplicationStatus(agencyId, applicationId, statusData);

        if (applicantRole === ROLES.CANDIDATE) {
            setIsMoveToAgencyDialogOpen(true);
        }
    };

    const handleOpenConfirmDialog = () => {
        setIsConfirmCloseMoveToAgencyDialogOpen(true);
    };

    const handleCloseConfirmAndMoveToAgencyDialogs = () => {
        setIsConfirmCloseMoveToAgencyDialogOpen(false);
        setIsMoveToAgencyDialogOpen(false);
    };

    const handleCloseConfirmDialog = () => {
        setIsConfirmCloseMoveToAgencyDialogOpen(false);
    };

    const handleStartDateChange = (value) => setStartDate(value);

    const getCandidateInfo = () => ({
        _id: candidateApplicationDetails.candidateId,
        name: `${candidateApplicationDetails.firstName} ${candidateApplicationDetails.lastName}`,
        profilePictureImgUrl: candidateApplicationDetails.profilePictureImgUrl,
        position: candidateApplicationDetails.position,
    });

    return (
        <>
            {(!hideAll && !isAcceptedAnotherOffer) &&
                <div className="interview-action-buttons">
                    <SecondaryButton text={intl.formatMessage({ id: "reject" })} data-testid="reject-btn" handleClick={handleRejectClick} />
                    {jobDetails.profileForwardType === JOB_PROFILE_FORWARD_TYPES[0].key && isPendingAdminReview
                        ? <PrimaryButton className="forward-button" text={intl.formatMessage({ id: "send-to-client" })} data-testid="forward-to-client-btn" handleClick={handleOpenAddCommentsDialog} />
                        : <PrimaryButton className="hire-button" text={intl.formatMessage({ id: "hire" })} data-testid="hire-btn" handleClick={handleOpenApproveDialog} />
                    }
                </div >
            }

            <RejectApplicationDialog
                agencyName={agencyName}
                isOpen={isRejectDialogOpen}
                stepOfRejection={(hasPreviousInterview || hasInterviewPassed) ? STEPS_OF_REJECTION.SECOND_STEP : STEPS_OF_REJECTION.FIRST_STEP}
                handleCloseDialog={() => setIsRejectDialogOpen(false)}
                handleRejectSubmit={handleRejectSubmit}
            />

            <RejectInterviewAndApplicationDialog
                isOpen={isRejectInterviewAndApplicationDialogOpen}
                stepOfRejection={STEPS_OF_REJECTION.SECOND_STEP}
                handleCloseDialog={() => setIsRejectInterviewAndApplicationDialogOpen(false)}
                handleRejectSubmit={handleRejectSubmit}
                handleRejectInterview={handleRejectInterview}
            />

            <ApproveApplicationDialog
                agencyId={agencyId}
                applicationId={applicationId}
                openDialog={isApproveDialogOpen}
                handleHireCandidate={handleHireCandidate}
                handleCloseDialog={handleCloseApproveDialog}
                isContractJob={candidateApplicationDetails.jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT}
                accountManager={candidateApplicationDetails.accountManager}
                isClientMode={isClientMode}
                isAgencyJob={clientId === agencyId}
                handleStartDateChange={handleStartDateChange}
            />

            {isAddCommentsDialogOpen &&
                <AddCommentsDialog
                    isAddCommentsDialogOpen={isAddCommentsDialogOpen}
                    handleCloseDialog={handleCloseAddCommentsDialog}
                    initialSelectedEmployees={[getCandidateInfo()]}
                    handleSendProfiles={handleForwardToClient}
                    showSearchbar={false}
                    notifierName={candidateApplicationDetails.company}
                />
            }

            {isMoveToAgencyDialogOpen && isAdminMode &&
                <MoveToEmployeesDialog
                    infoText={MOVE_TO_EMPLOYEES_HELPER_TEXT}
                    agencyId={agencyId}
                    userId={candidateApplicationDetails.candidateId}
                    userName={`${candidateApplicationDetails.firstName} ${candidateApplicationDetails.lastName}`}
                    openDialog={isMoveToAgencyDialogOpen}
                    handleCloseDialog={handleOpenConfirmDialog}
                    userRole={candidateApplicationDetails.role}
                    startDateInitialValue={startDate}
                />
            }

            { isConfirmCloseMoveToAgencyDialogOpen && isAdminMode &&
                <ConfirmDialog
                    openDialog={isConfirmCloseMoveToAgencyDialogOpen}
                    dialogTitle="Confirm"
                    dialogSubtitle="Are you sure you don't want to add the candidate to your list of employees?"
                    discardButtonName="No"
                    confirmButtonName="Yes"
                    handleCloseDialog={handleCloseConfirmDialog}
                    handleConfirmDialog={handleCloseConfirmAndMoveToAgencyDialogs}
                />
            }

        </>
    );
};

const mapStateToProps = state => ({
    candidateApplicationDetails: getCandidateApplicationDetails(state),
    jobDetails: getJobOpportunityDetails(state),
    isClientMode: isClient(state),
    isAdminMode: isAdmin(state),
});

const mapDispatchToProps = {
    updateInterviewStatus,
    updateApplicationStatus,
    sendProfileToClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationInterviewActions);
