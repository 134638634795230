import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { parseQueryString, updateQuery } from 'utils/helpers';

import AppliedCandidatesActions from '../AppliedCandidatesActions';
import AppliedCandidatesRedux from './AppliedCandidatesRedux';

import { mapQueryToFiltersAppliedCandidates } from 'utils/applicationUtils';
import { convertStringToLanguageKey } from 'utils/languageUtils';

import { Typography } from '@material-ui/core';
import {
    APPLIED_CANDIDATES_TITLE,
    FILTER_TABS,
    DEFAULT_FILTERS,
    DEFAULT_SORT,
} from './AppliedCandidatesConstants';

import { CANDIDATE_STATUS_SELECT } from '../AppliedCandidatesActions/AppliedCandidatesConstants';

import { ReactComponent as BriefcaseIcon } from 'assets/briefcase-icon.svg';

import './AppliedCandidates.scss';

let typingTimer;

const AppliedCandidates = ({
    dataSelector,
    statusCounts,
    displayMode,
    isAdmin,
    isSupplierAdmin,
    agencyId,
}) => {
    const intl = useIntl();
    const history = useHistory();

    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [sortValue, setSortValue] = useState(DEFAULT_SORT);

    useEffect(() => {
        const inputElement = document.getElementsByName('searchTerm')[0];

        inputElement.addEventListener('keyup', () => {
            clearTimeout(typingTimer);
        });
    }, []);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);
        setFilters(mapQueryToFiltersAppliedCandidates(parsedQuery, displayMode));
    }, [history.location.search]);

    const selectCandidateStatusTab = (tab) => {
        const updatedQuery = updateQuery(history.location.search, { status: tab });

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
        setFilters(oldState => ({ ...oldState, status: tab }));
    };

    const handleSortChange = (e) => {
        const sortType = e.target.name;
        const sortValues = e.target.value;

        const sortObj = {
            [sortType]: CANDIDATE_STATUS_SELECT.find(x => x.value === sortValues).name,
        };

        const updatedQuery = updateQuery(history.location.search, sortObj);
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
        setSortValue(sortObj.sortBy);
    };

    const handleSearchTermChange = (e) => {
        const text = e.target.value;

        const updatedQuery = updateQuery(history.location.search, { searchTerm: text });
        history.replace({ pathname: history.location.pathname, search: updatedQuery });

        setFilters(oldState => ({ ...oldState, searchTerm: text }));
    }

    return (
        <div className="applied-candidates-wrapper">
            <h2 className="applied-candidates-title">{intl.formatMessage({ id: convertStringToLanguageKey(APPLIED_CANDIDATES_TITLE[displayMode]) })}</h2>
            <Typography className="applied-candidates-sub-title">{intl.formatMessage({ id: "applied-candidates-subtitle" })} <BriefcaseIcon /></Typography>

            <div className="applied-candidates-tabs-container">
                {
                    FILTER_TABS[displayMode].map((tab, i) => (
                        <div className={`applied-candidates-tab ${filters.status === tab.name ? 'active-candidates-tab' : ''}`} key={(tab.value + i)} onClick={() => selectCandidateStatusTab(tab.name)}>
                            <h3 className="applied-candidates-tab-title">{intl.formatMessage({ id: convertStringToLanguageKey(tab.name) })} ({statusCounts[tab.value]})</h3>
                        </div>
                    ))
                }
            </div>

            <AppliedCandidatesActions
                value={sortValue}
                onChangeHandler={handleSortChange}
                searchTerm={filters.searchTerm}
                handleSearchTermChange={handleSearchTermChange}
            />

            <AppliedCandidatesRedux
                dataSelector={dataSelector}
                filters={filters}
                selectCandidateStatusTab={selectCandidateStatusTab}
                isAdmin={isAdmin}
                isSupplierAdmin={isSupplierAdmin}
                agencyId={agencyId}
            />

        </div >
    );
}

const mapStateToProps = (state, props) => ({
    statusCounts: props.statusCountSelector(state),
});

export default connect(mapStateToProps, null)(AppliedCandidates);
